<template>
<div>
    <div class="hero" :style="`background: url('https://app.restorani.bitlab.host/gallery/${restaurant.id}/medium/${restaurant.settings.header}'); background-size: cover; background-position: center;`">
      <img
      class="hero-logo"
      :src="
        conf.apiUrlBase +
        '/gallery/' +
        restaurant.id +
        '/' +
        restaurant.settings.logo
      "
    />
    </div>
  <v-container style="max-width: 1170px">
    <v-row>
      <h2 class="mb-5 mt-4">{{translate( $store.state.user.name) }} {{ translate($store.state.user.lastname) }}: {{ translate("Историјат наруџби") }}</h2>
    </v-row>
    <v-data-table dense :headers="headers" :items="meals" @click:row="openDialog">
      <template
        v-for="header in headers"
        v-slot:[`header.${header.value}`]="{ header }"
      >
        {{ translate(header.text) }}
      </template>
      <template
        v-slot:item.meals="{ item }"
      >
        <p class="naziv-obroka">{{ translate(item.meals.name) }}</p> 
      </template>
      <template
        v-slot:item.num="{ item,index }"
      >
       {{ index+1 }}
      </template>
    </v-data-table>
    

    <v-dialog  v-model="dialog" max-width="60%" scrollable>
      <v-card>
        <v-card-text>
          <v-card elevation="0">
            <v-card-title>
              <v-spacer></v-spacer>
              <v-btn icon @click="dialog = false">
                <v-icon x-large>mdi-close</v-icon>
              </v-btn>
            </v-card-title>
            <v-card-text class="mb-4">
              <v-row>
                <v-col cols="12" sm="6">
                  <v-img
                    :src="
                      selectedMeal.image
                        ? conf.apiUrlBase +
                          '/gallery/' +
                          restaurant +
                          '/medium/' +
                          selectedMeal.image.name
                        : 'https://cdn.vuetifyjs.com/images/cards/cooking.png'
                    "
                  >
                    <template v-slot:placeholder>
                      <v-row
                        class="fill-height ma-0"
                        align="center"
                        justify="center"
                      >
                        <v-progress-circular
                          indeterminate
                          color="grey lighten-5"
                        ></v-progress-circular>
                      </v-row>
                    </template>
                  </v-img>
                </v-col>
                <v-col cols="12" sm="6" class="ml-sm-n16 my-sm-2 mt-sm-16">
                  <v-row align="end" class="fill-height">
                    <v-card elevation="0" class="pa-5">
                      <v-card-title
                        ><h3>
                          {{ translate(selectedMeal.meals.name) }}
                        </h3></v-card-title
                      >

                      <v-card-text class="mb-4">
                        <v-row no-gutters>
                          <template v-for="(eat, i) in selectedMeal.meals.foods">
                            <v-col cols="12" class="font-weight-bold pt-2">
                              <v-row no-gutters justify="space-between">
                                <span
                                  ><h3>{{ translate(eat.name) }}</h3></span
                                >
                              </v-row>
                            </v-col>
                            <v-col cols="12">{{
                              translate(eat.description)
                            }}</v-col>
                          </template>
                        </v-row>
                      </v-card-text>
                    </v-card>
                  </v-row>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-card-text>
      </v-card>
    </v-dialog>
  </v-container>
  </div>
</template>

<script>
import axios from "../plugins/axios";
import conf from "../config";


export default {
  data() {
    return {
      meals: [],
      dialog: false,
      conf: conf,
      selectedMeal: {meals:{name:null,foods:{}}},
      counter: 0,
      headers:[
        {
          text: "Р.Бр.",
          value:"num",
        },
        {
          text: "Датум",
          value: "date",
        },
        {
          text: "Смјена",
          value: "shift",
        },
        {
          text: "Оброк",
          value: "meals",
        },
      ],
    };
  },
  computed: {
        restaurant() {
      return this.$store.state.restaurant
        ? this.$store.state.restaurant
        : this.$store.state.company.restaurant;
    },
  },
  created() {
    axios.get("/user/history").then((res) => {
      let modified = [];
      res.data.forEach(element => {
        element.shift = element.shift.replace('-smena', '');
        modified.push(element);
      });

      this.meals = modified;
    });
  },
  methods:{
      openDialog(item){
          this.dialog = true;
          this.selectedMeal = item
      }
  }
};
</script>


<style scoped>
.row {
  width: 100%;
}

.istorija-header {
  font-weight: bold;
  font-size: 19px;
}

.red-istorija {
  cursor: pointer;
}

.red-istorija p {
  margin: 0;
  padding: 0;
}

.red-istorija:nth-child(odd) {
  background: #f3f4f4;
}

.naziv-obroka {
  cursor: pointer;
  transition: all .2s;
  margin: 0;
  width: max-content;
  padding: 5px 15px;
}

.naziv-obroka:hover {
  font-weight: bold;
    background: #ededed;
    padding: 5px 14px;
    width: max-content;
}
</style>