<template>

  <v-container style="max-width: 90%;margin-top: 50px">
<!--    <h3 class="tab_style_header">{{ $t('your_profile')}}</h3>-->
<!--    <p style="color: rgba(0,0,0,0.5)">{{ $t('edit_profile')}}</p>-->
<!--    <hr>-->
<!--    <div class="season_tabs">-->

<!--      <div class="season_tab">-->
<!--        <input type="radio" id="tab-1" name="tab-group-1" checked>-->
<!--        <label class="tab_style" style="cursor: pointer" for="tab-1">{{ $t('gen_info')}}</label>-->

<!--        <div class="season_content">-->
<!--          <h4 class="tab_style">{{ $t('gen_info')}}</h4>-->
<!--          <p class="tab_style_sub_tekst">{{$t('gen_data')}}</p>-->
<!--          <hr>-->
<!--          <span>-->
<!--            <v-row style="width: 100%;margin: 0">-->
<!--              <div style="width: 50%">-->
<!--                <label style="font-size: 13px" for="">{{ $t('name') }} {{ translate('и') }}<span style="text-transform: lowercase"> {{  $t('last_name') }}</span> :</label>-->
<!--                <v-card style="box-shadow: none;border: 1px solid #cfcfcf;border-radius: 7px">-->
<!--                  <v-card-title style="font-size: 15px" v-if="$store.state.user.name">-->
<!--                    {{ translate( $store.state.user.name) }} {{ translate($store.state.user.lastname) }}-->
<!--                  </v-card-title>-->
<!--                </v-card>-->
<!--              </div>-->
<!--              <div style="width: 50%">-->
<!--                <label style="margin-left: 15px;display: block;font-size: 13px" for="">ID: </label>-->
<!--                <v-card style="box-shadow: none;border: 1px solid #cfcfcf;border-radius: 7px;margin-left: 15px">-->
<!--                  <v-card-title style="font-size: 15px" v-if="$store.state.user.id">{{$store.state.user.id}}</v-card-title>-->
<!--                </v-card>-->
<!--              </div>-->
<!--            </v-row>-->
<!--            <v-row style="width: 100%;margin: 15px 0 0 0">-->
<!--              <div style="width: 50%">-->
<!--                <label style="font-size: 13px" for="">{{ $t('address') }} : </label>-->
<!--                <v-card style="box-shadow: none;border: 1px solid #cfcfcf;border-radius: 7px">-->
<!--                  <v-card-title style="font-size: 15px" v-if="$store.state.user.address">-->
<!--                    {{ translate($store.state.user.address) }}-->
<!--                  </v-card-title>-->
<!--                  <v-card-title  v-else>-->
<!--                   {{ translate('...') }}-->
<!--                  </v-card-title>-->
<!--                </v-card>-->
<!--              </div>-->
<!--              <div style="width: 50%">-->
<!--                <label style="margin-left: 15px;display: block;font-size: 13px" for=""> {{$t('city')}} : </label>-->
<!--                <v-card style="box-shadow: none;border: 1px solid #cfcfcf;border-radius: 7px;margin-left: 15px">-->
<!--                  <v-card-title style="font-size: 15px" v-if="$store.state.user.city_id">-->
<!--                   {{ translate($store.state.user.city_id) }}-->
<!--                  </v-card-title>-->
<!--                  <v-card-title v-else>-->
<!--                   {{ translate('...') }}-->
<!--                  </v-card-title>-->
<!--                </v-card>-->
<!--              </div>-->
<!--            </v-row>-->
<!--            <v-row style="width: 100%;margin: 15px 0 0 0">-->
<!--              <div style="width: 50%">-->
<!--                <label style="font-size: 13px" for="">{{ $t('email') }} : </label>-->
<!--                <v-card style="box-shadow: none;border: 1px solid #cfcfcf;border-radius: 7px">-->
<!--                  <v-card-title style="font-size: 15px" v-if="$store.state.user.email">-->
<!--                    {{ translate($store.state.user.email) }}-->
<!--                  </v-card-title>-->
<!--                  &lt;!&ndash;-->
<!--                        <v-card-subtitle style="color:blue" v-if="$store.state.user.email_verified_at">Email je verifikovan</v-card-subtitle>-->
<!--                        <v-card-subtitle style="color:red" v-else>Email nije verifikovan</v-card-subtitle>-->
<!--                        &ndash;&gt;-->
<!--                </v-card>-->
<!--              </div>-->
<!--              <div style="width: 50%">-->
<!--                <label style="margin-left: 15px;display: block;font-size: 13px" for=""> {{$t('phone_no')}} : </label>-->
<!--                <v-card style="box-shadow: none;border: 1px solid #cfcfcf;border-radius: 7px;margin-left: 15px">-->
<!--                  <v-card-title style="font-size: 15px" v-if="$store.state.user.phone">-->
<!--                   {{ translate($store.state.user.phone) }}-->
<!--                  </v-card-title>-->
<!--                </v-card>-->
<!--              </div>-->
<!--            </v-row>-->
<!--            <v-card>-->
<!--              <v-card-title v-if="$store.state.user.external_code" class="my-4" >-->
<!--                {{$t('ekstern_code')}} : {{ translate($store.state.user.external_code) }}-->
<!--              </v-card-title>-->
<!--            </v-card>-->
<!--            <v-card>-->
<!--              <v-card-title v-if="$store.state.user.work_position" class="my-4" >-->
<!--                {{$t('work_place')}} : {{ translate($store.state.user.work_position) }}-->
<!--              </v-card-title>-->
<!--            </v-card>-->
<!--            <v-card-actions>-->
<!--              <v-btn :style="'border-radius: 8px;background: rgb(251, 109, 59);;color:#fff;box-shadow: none;text-transform: capitalize'" @click="$router.push({name: 'edit-profile'})" block large>-->
<!--                {{$t('change_profil')}}-->
<!--              </v-btn>-->
<!--            </v-card-actions>-->
<!--            <v-row class="mt-5" align="center" justify="center">-->
<!--          <v-card-text align="center" justify="center" >-->
<!--            {{$t('app_developed_by')}}<a href="https://bitlab.rs/" target="_blank" style="text-decoration: none"><strong > {{$t('bitlab')}}</strong></a>-->
<!--          </v-card-text>-->
<!--        </v-row>-->
<!--          </span>-->
<!--        </div>-->
<!--      </div>-->

<!--      <div class="season_tab">-->
<!--        <input type="radio" id="tab-2" name="tab-group-1">-->
<!--        <label class="tab_style" style="cursor: pointer" for="tab-2">{{ $t('password')}}</label>-->

<!--        <div class="season_content">-->
<!--          <h4 class="tab_style">{{ $t('password')}}</h4>-->
<!--          <p class="tab_style_sub_tekst">{{ $t('password_data')}}</p>-->
<!--          <hr>-->
<!--          <span>-->
<!--            <v-card-text style="padding: 0">-->
<!--              <v-form>-->
<!--                <v-text-field-->
<!--                    v-model="newPass"-->
<!--                    :label="$t('enter_new_password')"-->
<!--                    name="newPassword"-->
<!--                    prepend-icon="mdi-lock"-->
<!--                    type="password"-->
<!--                ></v-text-field>-->
<!--                <v-text-field-->
<!--                    v-model="newPassCheck"-->
<!--                    :label="$t('confirm_new_pass')"-->
<!--                    name="newPasswordCheck"-->
<!--                    prepend-icon="mdi-lock"-->
<!--                    type="password"-->
<!--                ></v-text-field>-->
<!--                <v-alert dense outlined type="error" v-if="failedCheck">-->
<!--                  {{$t('entered_passwords_do_not_match')}}-->
<!--                </v-alert>-->
<!--                <v-text-field-->
<!--                    v-model="currPass"-->
<!--                    :label="$t('enter_your_password')"-->
<!--                    name="currPassword"-->
<!--                    prepend-icon="mdi-lock"-->
<!--                    type="password"-->
<!--                ></v-text-field>-->

<!--                <v-alert dense outlined type="error" v-if="failedPass">-->
<!--                  {{$t('incorrect_password')}}-->
<!--                </v-alert>-->
<!--              </v-form>-->
<!--            </v-card-text>-->
<!--            <v-card-actions>-->
<!--              <v-spacer></v-spacer>-->
<!--              <v-btn class="px-10" :style="'border-radius: 8px;background: rgb(251, 109, 59);;color:#fff;box-shadow: none;text-transform: capitalize'" @click="update">{{$t('confirm_pass_change')}}</v-btn>-->
<!--              <v-spacer></v-spacer>-->
<!--            </v-card-actions>-->
<!--          </span>-->
<!--        </div>-->
<!--      </div>-->
<!--    </div>-->

    <v-row>
      <v-col cols="12">
        <h3 class="tab_style_header">{{ $t('your_profile')}}</h3>
        <p style="color: rgba(0,0,0,0.5)">{{ $t('edit_profile')}}</p>
        <v-divider></v-divider>
      </v-col>
      <v-col cols="12" sm="4">
        <v-list-item-group
          v-model="selectedItem"
          color="#FB6D3B"
        >
          <v-list-item
            v-for="(item, i) in [$t('gen_info'), $t('password')]"
            :key="i"
          >
            <v-list-item-content>
              <v-list-item-title v-text="item"></v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list-item-group>
      </v-col>
      <v-col cols="12" sm="8" v-if="selectedItem === 0">
        <h4 class="tab_style">{{ $t('gen_info')}}</h4>
        <p class="tab_style_sub_tekst">{{$t('gen_data')}}</p>
        <v-divider></v-divider>

<!--        <v-row>-->
<!--          <v-col cols="12" sm="6">-->
<!--            <v-text-field-->
<!--              :label="$t('name') + ' ' + translate('и') + ' ' + $t('last_name')"-->
<!--              outlined-->
<!--              hide-details-->
<!--              :value="translate( $store.state.user.name) + ' ' + translate($store.state.user.lastname)"-->
<!--              readonly-->
<!--            >-->
<!--            </v-text-field>-->
<!--          </v-col>-->
<!--          <v-col cols="12" sm="6">-->
<!--            <v-text-field-->
<!--              label="ID"-->
<!--              outlined-->
<!--              hide-details-->
<!--              :value="$store.state.user.id"-->
<!--              readonly-->
<!--            >-->
<!--            </v-text-field>-->
<!--          </v-col>-->
<!--          <v-col cols="12" sm="6">-->
<!--            <v-text-field-->
<!--              :label="$t('address')"-->
<!--              outlined-->
<!--              hide-details-->
<!--              :value="translate($store.state.user.address)"-->
<!--              readonly-->
<!--            >-->
<!--            </v-text-field>-->
<!--          </v-col>-->
<!--          <v-col cols="12" sm="6">-->
<!--            <v-text-field-->
<!--              :label="$t('city')"-->
<!--              outlined-->
<!--              hide-details-->
<!--              :value="translate($store.state.user.city_id)"-->
<!--              readonly-->
<!--            >-->
<!--            </v-text-field>-->
<!--          </v-col>-->
<!--          <v-col cols="12" sm="6">-->
<!--            <v-text-field-->
<!--              :label="$t('email')"-->
<!--              outlined-->
<!--              hide-details-->
<!--              :value="translate($store.state.user.email)"-->
<!--              readonly-->
<!--            >-->
<!--            </v-text-field>-->
<!--          </v-col>-->
<!--          <v-col cols="12" sm="6">-->
<!--            <v-text-field-->
<!--              :label="$t('phone_no')"-->
<!--              outlined-->
<!--              hide-details-->
<!--              :value="translate($store.state.user.phone)"-->
<!--              readonly-->
<!--            >-->
<!--            </v-text-field>-->
<!--          </v-col>-->
<!--          <v-col cols="12" sm="6" v-if="$store.state.user.external_code">-->
<!--            <v-text-field-->
<!--              :label="$t('ekstern_code')"-->
<!--              outlined-->
<!--              hide-details-->
<!--              :value="translate($store.state.user.external_code)"-->
<!--              readonly-->
<!--            >-->
<!--            </v-text-field>-->
<!--          </v-col>-->
<!--          <v-col cols="12" sm="6" v-if="$store.state.user.work_position">-->
<!--            <v-text-field-->
<!--              :label="$t('work_place')"-->
<!--              outlined-->
<!--              hide-details-->
<!--              :value="translate($store.state.user.work_position)"-->
<!--              readonly-->
<!--            >-->
<!--            </v-text-field>-->
<!--          </v-col>-->
<!--          <v-col cols="12">-->
<!--            <v-btn :style="'border-radius: 8px;background: rgb(251, 109, 59);;color:#fff;box-shadow: none;text-transform: capitalize'" @click="$router.push({name: 'edit-profile'})" block large>-->
<!--              {{$t('change_profil')}}-->
<!--            </v-btn>-->
<!--          </v-col>-->
<!--          <v-col cols="12" class="py-4" align="center">-->
<!--            {{$t('app_developed_by')}}<a href="https://bitlab.rs/" target="_blank" style="text-decoration: none"><strong > {{$t('bitlab')}}</strong></a>-->
<!--          </v-col>-->
<!--        </v-row>-->

        <v-form>
          <v-row>
            <v-col cols="12" sm="6">
              <v-text-field
                v-model="$store.state.user.id"
                label="ID"
                outlined
                hide-details
                disabled
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="6"></v-col>
            <v-col cols="12" sm="6">
              <v-text-field
                v-model="$store.state.user.name"
                :label="$t('name')"
                outlined
                hide-details
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="6">
              <v-text-field
                v-model="$store.state.user.lastname"
                :label="$t('last_name')"
                outlined
                hide-details
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="6">
              <v-text-field
                v-model="$store.state.user.email"
                :label="$t('email')"
                outlined
                hide-details
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="6">
              <v-text-field
                v-model="$store.state.user.phone"
                :label="$t('phone_no')"
                outlined
                hide-details
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="6">
              <v-select
                @change="getCitiesByCountry"
                :items="countries"
                item-value="id"
                item-text="name"
                v-model="$store.state.user.country"
                :label="$t('country')"
                outlined
                hide-details>
              </v-select>
            </v-col>
            <v-col cols="12" sm="6">
              <v-select
                :items="cities"
                item-value="id"
                item-text="name"
                v-model="$store.state.user.city_id"
                :label="$t('city')"
                outlined
                hide-details>
              </v-select>
            </v-col>
<!--            <v-col cols="12" sm="6">-->
<!--              <v-text-field-->
<!--                v-model="$store.state.user.firm"-->
<!--                :label="$t('firm')"-->
<!--                outlined-->
<!--                hide-details-->
<!--              ></v-text-field>-->
<!--            </v-col>-->
            <v-col cols="12" sm="6">
              <v-text-field
                v-model="$store.state.user.address"
                :label="$t('address')"
                outlined
                hide-details
              ></v-text-field>
            </v-col>
<!--            <v-col cols="12" sm="6">-->
<!--              <v-text-field-->
<!--                v-model="$store.state.user.apartment"-->
<!--                :label="$t('apartment')"-->
<!--                outlined-->
<!--                hide-details-->
<!--              ></v-text-field>-->
<!--            </v-col>-->
<!--            <v-col cols="12" sm="6">-->
<!--              <v-text-field-->
<!--                v-model="$store.state.user.floor"-->
<!--                :label="$t('floor')"-->
<!--                outlined-->
<!--                hide-details-->
<!--              ></v-text-field>-->
<!--            </v-col>-->
            <v-col cols="12" sm="6">
              <v-select
                v-model="notification"
                :items="items"
                :label="$t('select_notification')"
                outlined
                hide-details
              ></v-select>
            </v-col>
            <v-col cols="12" sm="12">
            <v-text-field
              v-model="password"
              :label="$t('pass_confirm')"
              prepend-inner-icon="mdi-lock"
              type="password"
              outlined
              hide-details
            ></v-text-field>
            </v-col>
            <v-col cols="12" sm="12">
              <v-btn block class="px-16" :style="'border-radius: 8px;background: rgb(251, 109, 59);;color:#fff;box-shadow: none;text-transform: capitalize'" @click="updateInfo">
                {{ $t('verify_change') }}
              </v-btn>
            </v-col>
          </v-row>

          <v-alert dense outlined type="error" v-if="failed">
            Погрешна лозинка
          </v-alert>
        </v-form>

      </v-col>

      <v-col cols="12" sm="8" v-if="selectedItem === 1">
          <h4 class="tab_style">{{ $t('password')}}</h4>
          <p class="tab_style_sub_tekst">{{ $t('password_data')}}</p>
          <v-divider></v-divider>
          <v-card elevation="0">
            <v-card-text style="padding: 0">
              <v-form>
                <v-text-field
                  v-model="newPass"
                  :label="$t('enter_new_password')"
                  name="newPassword"
                  prepend-icon="mdi-lock"
                  type="password"
                ></v-text-field>
                <v-text-field
                  v-model="newPassCheck"
                  :label="$t('confirm_new_pass')"
                  name="newPasswordCheck"
                  prepend-icon="mdi-lock"
                  type="password"
                ></v-text-field>
                <v-alert dense outlined type="error" v-if="failedCheck">
                  {{$t('entered_passwords_do_not_match')}}
                </v-alert>
                <v-text-field
                  v-model="currPass"
                  :label="$t('enter_your_password')"
                  name="currPassword"
                  prepend-icon="mdi-lock"
                  type="password"
                ></v-text-field>

                <v-alert dense outlined type="error" v-if="failedPass">
                  {{$t('incorrect_password')}}
                </v-alert>
              </v-form>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn class="px-10" :style="'border-radius: 8px;background: rgb(251, 109, 59);;color:#fff;box-shadow: none;text-transform: capitalize'" @click="update">{{$t('confirm_pass_change')}}</v-btn>
              <v-spacer></v-spacer>
            </v-card-actions>
          </v-card>
      </v-col>
    </v-row>

  </v-container>

</template>

<script>
import conf from '../config'
import axios from "@/plugins/axios";


export default {
  data: () => ({
    selectedItem: 0,
    conf: conf,
    dialog: false,
    countries: [],
    country: '',
    city: '',
    cities: [{name : 'Изаберите државу'}],
    failedPass: false,
    failedCheck: false,
    newPass: null,
    newPassCheck: null,
    currPass: null,

    password:null,
    notification:null,
    items:['email','telefon'],
    failed:false,

  }),
  computed: {
        restaurant() {
      return this.$store.state.restaurant
        ? this.$store.state.restaurant
        : this.$store.state.company.restaurant;
    },
  },
  created() {
    this.countryCity();
  },
  methods: {
    updateInfo() {
      this.failed=false;
      axios.post("/user/edit-profile/" + this.$store.state.user.id, {
        name: this.$store.state.user.name,
        lastname: this.$store.state.user.lastname,
        email: this.$store.state.user.email,
        phone: this.$store.state.user.phone,
        address: this.$store.state.user.address,
        firm: this.$store.state.user.firm,
        country: this.$store.state.user.country,
        city: this.$store.state.user.city_id,
        apartment: this.$store.state.user.apartment,
        password: this.password,
        notification:this.notification,
        _method: "patch",
      }).then(res => {

      })
        .catch(()=>{
          this.failed=true;
        })
    },
    update() {
      this.failedCheck=false;
      this.failedPass=false;
      if (this.newPass != this.newPassCheck) {
        this.failedCheck = true;
      } else {
        axios
            .post("/user/change-password",{
              new_password: this.newPass,
              old_password: this.currPass,
              id:this.$store.state.user.id
            })
            .then((res) => {
              //this.$router.push({name: 'profil'})
              this.newPass = null
              this.newPassCheck = null
              this.currPass = null
            })
            .catch(() => {
              this.failedPass=true;
            });
      }
    },
    obrisiProfil(user) {
      axios.post('/user/obrisi-profil', {'user': user}).then(({data}) => {
        this.dialog = false;
        localStorage.removeItem("restoran-token");
        this.$store.commit("SAVE_USER", null);
        this.$store.commit("SAVE_RESTAURANT", null);
        this.$store.commit("SAVE_COMPANY", null);
        this.$router.push({ name: "restorani-guest" });
      })
    },
    countryCity() {
      axios.get('/restaurants/countries-all').then(({data}) => {
        this.countries = data
        if (this.$store.state.user.city_id) {
          this.cities = []
          data.filter(country => {
            country.city.filter(citi => {
              if (citi.id == this.$store.state.user.city_id) {
                this.country = country.id
                this.cities = country.city
              }
            })
          })
        }
      })
    },
    getCitiesByCountry() {
      this.cities = [{name : 'Изаберите државу'}]
      this.countries.filter(country => {
        if (country.id == this.$store.state.user.country) {
          this.cities = country.city
        }
      })
    },
  }
}
</script>

<style scoped>
label,p, div {
  font-family: 'Poppins', Helvetica, sans-serif !important;
}
hr {
  display: block;
  height: 1px;
  border: 0;
  border-top: 1px solid #cfcfcf;
  margin: 1em 0;
  padding: 0;
}
.avatar-user {
  width: 150px;
  margin: 0 auto;
  display: block;
  margin-top: 50px;
}
.season_tabs {
  position: relative;
  min-height: 500px; /* This part sucks */
  clear: both;
  margin: 25px 0;
}
.season_tab {
  float: left;
  clear: both;
  width: 286px;
}
.season_tab label {
  padding: 5px;
  margin-left: -1px;
  font-size: 17px;
  vertical-align: middle;
  position: relative;
  left: 1px;
  width: 264px;
  height: 38px;
  display: table-cell;
}
.season_tab [type=radio] {
  display: none;
}
.season_content {
  position: absolute;
  top: 0;
  left: 40%;
  background: white;
  right: 0;
  bottom: 0;
  padding: 20px;
}
.season_content span {
  animation: 0.5s ease-out 0s 1 slideInFromTop;
}
[type=radio]:checked ~ label {
  background: white;
  font-weight: 700;
  z-index: 2;
}
[type=radio]:checked ~ label ~ .season_content {
  z-index: 1;
}
.tab_style {
  font-size: 18px;
  font-family: 'Poppins', Helvetica, sans-serif;
}
.tab_style_header {
  font-size: 28px;
  font-family: 'Poppins', Helvetica, sans-serif;
}
.tab_style_sub_tekst {
  font-size: 15px;
  color: rgba(0, 0, 0, 0.55);
}
</style>
