<template>
  <v-container style="height: 100vh">
    <iframe :src="`https://eankete.com/sr/anketa/${$route.params.q.qid}/ordera`" width="100%" height="100%" frameborder="0"></iframe>
  </v-container>
</template>

<script>

export default {
  computed: {
    company() {
      return this.$store.state.company
    }
  }
}

</script>
