/* eslint-disable no-console */

import { register } from 'register-service-worker'

if (process.env.NODE_ENV === 'production') {
  register(`${process.env.BASE_URL}service-worker.js`, {
    ready () {
      // console.log(
      //   'App is being served from cache by a service worker.\n' +
      //   'For more details, visit https://goo.gl/AFskqB'
      // )
    },
    registered () {
      //console.log('Service worker has been registered.')
    },
    cached () {
      //console.log('Content has been cached for offline use.')
    },
    updatefound () {
      //console.log('New content is downloading.')
    },
    updated () {
      //console.log('New content is available; please refresh.')
    },
    offline () {
      //console.log('No internet connection found. App is running in offline mode.')
    },
    error (error) {
      //console.error('Error during service worker registration:', error)
    }
  })
}

self.addEventListener('message', function(event) {
  //console.log('sw message', event)
  if (event.data && event.data.command === 'clearCache') {
    //console.log('sw clearCache')

    self.addEventListener('activate', function(event) {
      event.waitUntil(
        caches.keys().then(function(cacheNames) {
          return Promise.all(
            cacheNames.map(function(cacheName) {
              // Check if the cache name should be cleared (e.g., clear all caches)
              if (cacheName.startsWith('Topli-Obrok')) {
                return caches.delete(cacheName);
              }
            })
          );
        })
      );
    });

  }
});
