<template>
  <v-container>
    <v-row>
    <v-col cols="12">
      <v-card>
        <v-card-title>
          <v-dialog
            ref="dialog"
            v-model="modal"
            :return-value.sync="date"
            persistent
            width="290px"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                v-model="date"
                label="Picker in dialog"
                prepend-inner-icon="mdi-calendar"
                readonly
                v-bind="attrs"
                v-on="on"
                hide-details
                class="px-2"
              ></v-text-field>
            </template>
            <v-date-picker
              v-model="date"
              type="month"
              scrollable
            >
              <v-spacer></v-spacer>
              <v-btn
                text
                color="primary"
                @click="modal = false"
              >
                Cancel
              </v-btn>
              <v-btn
                text
                color="primary"
                @click="$refs.dialog.save(date), loadReport()"
              >
                OK
              </v-btn>
            </v-date-picker>
          </v-dialog>
          <v-text-field
            v-model="search"
            prepend-inner-icon="mdi-magnify"
            label="Search"
            single-line
            hide-details
            class="px-2"
          ></v-text-field>
          <v-checkbox
            label="Prikazi sve"
            hide-details
            v-model="allDay"
            @change="filterDay"
          >
          </v-checkbox>
        </v-card-title>
        <v-card-text align="center" class="title">
          Izvestaj za {{monthName}} {{year}}
        </v-card-text>

        <v-simple-table class="py-2">
          <template v-slot:default>
            <thead>
              <tr>
                <th class="text-left">
                  Datum
                </th>
                <th class="text-left">
                  Smene
                </th>
              </tr>
            </thead>
            <tbody>
            <template v-for="item in dessertsReport">
              <tr>
                <td>{{item.day}}</td>
                <td>
                  <table width="100%">
                    <tr v-if="item.shiftOne && item.shiftOne.length">
                      <th class="px-4" width="200px" style="background: #f7f7f7">Prva</th>
                      <td class="px-4">
                        <table width="100%">
                          <template v-for="menu in item.shiftOne">
                            <tr>
                              <td class="py-2" width="80%">{{menu.meal_name}}</td>
                              <td class="py-2" align="center">{{menu.quantity}}</td>
                            </tr>
                          </template>
                        </table>
                      </td>
                    </tr>
                    <tr v-if="item.shiftTwo && item.shiftTwo.length">
                      <th class="px-4" width="200px" style="background: #f7f7f7">Druga</th>
                      <td class="px-4">
                        <table width="100%">
                          <template v-for="menu in item.shiftTwo">
                            <tr>
                              <td class="py-2" width="80%">{{menu.meal_name}}</td>
                              <td class="py-2" align="center">{{menu.quantity}}</td>
                            </tr>
                          </template>
                        </table>
                      </td>
                    </tr>
                    <tr v-if="item.shiftThree && item.shiftThree.length">
                      <th class="px-4" width="200px" style="background: #f7f7f7">Treca</th>
                      <td class="px-4">
                        <table width="100%">
                          <template v-for="menu in item.shiftThree">
                            <tr>
                              <td class="py-2" width="80%">{{menu.meal_name}}</td>
                              <td class="py-2" align="center">{{menu.quantity}}</td>
                            </tr>
                          </template>
                        </table>
                      </td>
                    </tr>
                  </table>
                </td>
              </tr>
            </template>
            <tr>
              <td class="title">UKUPNO:</td>
              <td class="title" align="end">
                <span class="mr-15 pr-4">{{ total }}</span>
              </td>
            </tr>
            </tbody>
          </template>
        </v-simple-table>


      </v-card>
    </v-col>
  </v-row>
  </v-container>
</template>


<script>

import axios from "../plugins/axios";
import moment from "moment";

export default {
  data() {
    return {
      moment: moment,
      search: '',
      headers: [
        {
          text: 'Dan',
          align: 'start',
          filterable: false,
          value: 'day',
        },
        { text: 'Naziv', value: 'name' },
        { text: 'Smena 1', value: 'shiftOne.quantity' },
        { text: 'Smena 2', value: 'shiftTwo.quantity' },
        { text: 'Smena 3', value: 'shiftThree.quantity' },
        { text: 'Ukupno', value: 'total' }
      ],
      desserts: [],
      date: new Date().toISOString().substr(0, 7),
      menu: false,
      modal: false,
      loading: false,
      dessertsReport: [],
      dessertsReportAll: [],
      allDay: false
    }
  },
  created() {
    this.loadReport()
  },
  methods: {
    filterDay() {
      if(this.allDay) {
        this.dessertsReport = [...this.dessertsReportAll]
      }
      else {
        this.dessertsReport = this.dessertsReportAll.filter(item => {
          if(item.shiftOne && item.shiftOne.length) {
            return item
          }
          if(item.shiftTwo && item.shiftTwo.length) {
            return item
          }
          if(item.shiftThree && item.shiftThree.length) {
            return item
          }
        })

        //this.dessertsReport = this.dessertsReportAll
      }
    },
    loadReport() {
      this.loading = true
      axios.post('/user/orders-by-month', {
        month: this.month,
        year: this.year,
        user_id: this.user.id
      }).then(res => {
        this.desserts = res.data
        this.report()
      }).finally(() => {
        this.loading = false
      })
    },
    report() {
      this.dessertsReport = []
      for(let day = 1; day <= this.daysInMonth; day++) {

        let checks = this.desserts.filter(item => {
          return item.date === moment(this.year + '-' + this.month + '-' + day).format('YYYY-MM-DD')
        })

        if(checks.length) {
          checks.forEach(check => {

            let index = this.dessertsReport.findIndex(item => item.day === day)
            if(check.shift === 1) {
              if(index > 0) {
                if(!this.dessertsReport[index].shiftOne) this.dessertsReport[index].shiftOne = []
                this.dessertsReport[index].shiftOne.push(check)
              }
              else {
                this.dessertsReport.push({day: day, shiftOne: [check]})
              }
            }
            else if (check.shift === 2) {
              if(index > 0) {
                if(!this.dessertsReport[index].shiftTwo) this.dessertsReport[index].shiftTwo = []
                this.dessertsReport[index].shiftTwo.push(check)
              }
              else {
                this.dessertsReport.push({day: day, shiftTwo: [check]})
              }
            }
            else if (check.shift === 3) {
              if(index > 0) {
                if(!this.dessertsReport[index].shiftThree) this.dessertsReport[index].shiftThree = []
                this.dessertsReport[index].shiftThree.push(check)
              }
              else {
                this.dessertsReport.push({day: day, shiftThree: [check]})
              }
            }
          })
        }
        else {
          this.dessertsReport.push({day, shiftOne: [], shiftTwo: [], shiftThree: []})
        }
      }

      this.dessertsReportAll = [...this.dessertsReport]
      this.filterDay()
    }
  },
  computed: {
    total() {
      let total = 0
      this.dessertsReport.forEach(item => {
        if(item.shiftOne && item.shiftOne.length) {
          item.shiftOne.forEach(menu => {
            total += menu.quantity
          })
        }
        if(item.shiftTwo && item.shiftTwo.length) {
          item.shiftTwo.forEach(menu => {
            total += menu.quantity
          })
        }
        if(item.shiftThree && item.shiftThree.length) {
          item.shiftThree.forEach(menu => {
            total += menu.quantity
          })
        }
      })
      return total
    },
    daysInMonth() {
      return this.moment().month(this.month).daysInMonth()
    },
    monthName() {
      return this.moment().lang("sr").month(this.month - 1).format('MMMM').toUpperCase()
    },
    month() {
      return parseInt(this.date.split('-')[1])
    },
    year() {
      return parseInt(this.date.split('-')[0])
    },
    user() {
      return this.$store.state.user
    }
  }
}

</script>
