// import firebase from "firebase/compat/app";
// import "firebase/firestore";
// import Vue from 'vue'
// import VueFire from 'vuefire'
//
//
// Vue.use(VueFire);

import { getFirestore, collection, getDocs, onSnapshot, doc, query, orderBy, limit } from "firebase/firestore";
import { initializeApp } from "firebase/app";


// TODO: Replace the following with your app's Firebase project configuration
// See: https://support.google.com/firebase/answer/7015592
const firebaseConfig = {
  apiKey: "AIzaSyAHBwr45SrkzMN9v4-tC0_hvHTqChowbMk",
  authDomain: "order-notification-8f5f2.firebaseapp.com",
  projectId: "order-notification-8f5f2",
  storageBucket: "order-notification-8f5f2.appspot.com",
  messagingSenderId: "802418955686",
  appId: "1:802418955686:web:ccf93c4f373c424ca16b67",
  measurementId: "G-FZNHEQZK12"
};

const app = initializeApp(firebaseConfig);
const db = getFirestore(app);

export {
  db,
  collection,
  getDocs,
  onSnapshot,
  doc,
  query,
  orderBy,
  limit
}

