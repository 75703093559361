<template>
  <v-container>
    <v-card :key="index" v-for="(meal, index) in meals" max-width="374" class="mx-auto my-12">
      <v-img
        height="250"
        :src="
          meal.image
            ? conf.apiUrlBase +
              '/gallery/' +
              $route.params.id +
              '/medium/' +
              meal.image.name
            : 'https://cdn.vuetifyjs.com/images/cards/cooking.png'
        "
      ></v-img>
      <v-card-title>{{ translate(meal.name) }}</v-card-title>
      <v-card-subtitle></v-card-subtitle>
      <v-card-text>
        {{ translate(meal.description) }}
      </v-card-text>
      <v-btn small text class="primary mt-5 mr-1" @click="toggle(meal);">
        <v-icon v-if="meal.is_checked" class="mr-2"
          >mdi-checkbox-marked-circle</v-icon
        >
        <v-icon v-else class="mr-2">mdi-circle</v-icon>
      </v-btn>
    </v-card>
    
    <v-btn
      fixed
      v-if="checked.length"
      bottom
      class="mx-auto"
      width=400
      color='primary'
      @click="dialog=true"
    >
      {{ $t("confirm") }}  {{checked.length}}
    </v-btn>
     <v-dialog
      v-model="dialog"
      scrollable
      width="400"
      max-heigth="700"
    >

      <v-card>
          <v-card :key="inx" v-for="(meal, inx) in checked">
          <v-img
            height=250
            :src="
              meal.image
                ? conf.apiUrlBase +
                  '/gallery/' +
                  $route.params.id +
                  '/medium/' +
                  meal.image.name
                : 'https://cdn.vuetifyjs.com/images/cards/cooking.png'
            "
          ></v-img>
          <v-card-title>{{ translate(meal.name) }}</v-card-title>
        </v-card>
          <v-btn class="primary"  @click="buy()">
          <v-icon 
            >mdi-checkbox-marked-circle</v-icon
          >
        </v-btn>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import axios from "../plugins/axios";
import conf from "../config";
import moment from "moment";
export default {
  data: () => ({
    conf: conf,
    dialog:false,
    moment: moment,
    search: "",
    idProp: null,
    dialogDelete: false,
    restaurantName: "",
    meals: [],
    date: new Date().toISOString().substr(0, 10),
  }),

  created() {
    this.initialize();
  },
 computed: {
    checked() {
      return   this.meals.filter(el=>el.is_checked);
    },
  },
  methods: {
    initialize() {
      axios
        .get(`/delivery/${this.$route.params.id}?date=${this.date}&type=2`)
        .then((res) => {
          res.data.meals.forEach((element)=>{
            element.is_checked=0;
          })
          this.meals = res.data.meals;
        });
    },
    toggle($meal) {
      $meal.is_checked = !$meal.is_checked;
      this.$forceUpdate();
    },
    buy(){
      this.dialog=false;
    }
  },
};
</script>