import axios from 'axios'
import cfg from '../config'
import store from '../store'
import VueI18n from 'vue-i18n'

let instance = axios.create({baseURL: cfg.apiUrl});


instance.interceptors.request.use(function (config) {

    if (window.localStorage.getItem('restoran-token')) {
        const token = window.localStorage.getItem('restoran-token');
        config.headers.Authorization = 'Bearer ' + token;
        if(store.state.restaurant) config.headers.restaurant = store.state.restaurant.id;
        if(store.state.company) config.headers.company = store.state.company.id;
    }
    if(window.localStorage.getItem('restorani-lang')) {
      config.headers.lang = window.localStorage.getItem('restorani-lang') || 'sr'
    }

    return config;
});
export default instance;
