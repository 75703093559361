import Vue from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify';
var convert = require("cyrillic-to-latin");
import 'leaflet/dist/leaflet.css'
import VueI18n from 'vue-i18n'
Vue.use(VueI18n);
const i18n = new VueI18n({
  locale: 'sr',
  fallbackLocale: 'sr',
  messages: {
    'sr': require('./locales/sr.json'),
    'rs': require('./locales/rs.json'),
    'sr-lat' : require('./locales/sr-lat.json'),
    'rs-lat' : require('./locales/rs-lat.json'),
    'hr': require('./locales/hr.json'),
    'bs': require('./locales/bs.json'),
    'en': require('./locales/en.json'),
    'mk': require('./locales/mk.json'),
    'si': require('./locales/si.json')
  },
  fallbackWarn: true,
  missingWarn: true,
  silentTranslationWarn: true
})
Vue.config.productionTip = false
Vue.mixin({
  methods:{
    translate:function(string){
      let restoran = ''
      if (this.$store.state.user && this.$store.state.user.type && this.$store.state.user.type == 'customer') {
        restoran = this.$store.state.company.restaurant
      } else if (this.$store.state.user && this.$store.state.user.type && this.$store.state.user.type == 'company') {
        restoran = this.$store.state.company.restaurant
      } else if (this.$store.state.user && this.$store.state.user.type && this.$store.state.user.type == 'recipient') {
        // restoran = this.$store.state.company.restaurant
        restoran = this.$store.state.company && this.$store.state.company.restaurant ? this.$store.state.company.restaurant : this.$store.state.restaurant
      } else {
        restoran = this.$store.state.restaurant
      }

      if(restoran && restoran.lang == 'ekavica') {
        let stringsE = {};
        stringsE['Извјештај'] = 'Извештај'
        stringsE['Цијена'] = 'Цена'
        stringsE["Успјешно испоручено"] = 'Успешно испоручено'
        stringsE['Сриједа'] = 'Среда'
        stringsE['Понедјељак'] = 'Понедељак'
        stringsE['Недјеља'] = 'Недеља'
        stringsE['Смјена 1'] = 'Смена 1'
        stringsE["1. Смјена"] = '1. Смена'
        stringsE['Смјена 1'] = 'Смена 1'
        stringsE['Смјена 2'] = 'Смена 2'
        stringsE['2. Смјена'] = '2. Смена'
        stringsE['Смјена 3'] = 'Смена 3'
        stringsE['3. Смјена'] = '3. Смена'
        stringsE['Смјена 4'] = 'Смена 4'
        stringsE['Свјеж краставац'] = 'Свеж краставац'
        stringsE['Прва смјена'] = 'Прва смена'
        stringsE['Друга смјена'] = 'Друга смена'
        stringsE['Трећа смјена'] = 'Трећа смена'
        stringsE['НАРУЧИВАЊЕ, ОТКАЗИВАЊЕ, ПРОМЈЕНА СМЈЕНЕ ЗА НАРЕДНУ СЕДМИЦУ ОБАВЕЗНО ДО '] = 'НАРУЧИВАЊЕ, ОТКАЗИВАЊЕ, ПРОМЕНА СМЕНЕ ЗА НАРЕДНУ СЕДМИЦУ ОБАВЕЗНО ДО '
        stringsE['НАРУЧИВАЊЕ, ОТКАЗИВАЊЕ, ПРОМЈЕНА СМЈЕНЕ ОБАВЕЗНО'] = 'НАРУЧИВАЊЕ, ОТКАЗИВАЊЕ, ПРОМЕНА СМЕНЕ ОБАВЕЗНО'
        stringsE['НАРУЧИВАЊЕ, ОТКАЗИВАЊЕ, ПРОМЈЕНА СМЈЕНЕ'] = 'НАРУЧИВАЊЕ, ОТКАЗИВАЊЕ, ПРОМЕНА СМЕНЕ'
        if (Object.keys(stringsE).includes(string)) {
          string = stringsE[string]
        }
      }

      if(typeof string!=='string')return string;
      if (store.state.isLatin) {
        return convert(string);
      } else {
        return string;
      }
    }
  }
})

new Vue({
  router,
  store,
  vuetify,
  i18n,
  render: h => h(App)
}).$mount('#app')

