<template>
  <v-container>
    <v-row>
      <v-simple-table>
        <template v-slot:default>
          <thead>
          <tr>
            <th class="text-left">
              ID
            </th>
            <th class="text-left">
              Name
            </th>
            <th class="text-left">
              Meals
            </th>
          </tr>
          </thead>
          <tbody>
          <tr
              v-for="order in orders"
              :key="order.id"
          >
            <td>{{order.id}}</td>
            <td>{{order.user.name}}</td>
            <td>
              <span v-for="(meal, i) in order.orders">
                {{meal.name}}<span v-if="order.orders.length - 1 > i">,</span>
              </span>
            </td>
          </tr>
          </tbody>
        </template>
      </v-simple-table>
    </v-row>
  </v-container>
</template>
<script>
import { db } from '../plugins/firebase'
export default {
  data() {
    return {
      orders: []
    }
  },
  created() {
    this.getTodos()
  },
  methods: {
    async getTodos() {
      let todosRef = await db
          .collection("orders")
          .orderBy('created_at', 'desc')
      todosRef.onSnapshot(snap => {
        this.orders = []
        snap.forEach(doc => {
          let data = doc.data()
          data.id = doc.id;
          this.orders.push(data);
        });
        setTimeout(() => {
          new Audio('./sounds/pogresna-lokacija.mp4').play();
        }, 1000)
      });
    }
  }
}
</script>