<template>
<div style="width: 95%;margin: 10px auto">
  <v-toolbar flat class="dimensions1">
    <v-toolbar-title>{{ $t("companies") }}</v-toolbar-title>
    <v-divider class="mx-4" inset vertical></v-divider>
    <v-row>
      <v-col cols="6" sm="4">
        <v-text-field
          class=" mt-4"
          v-model="search"
          append-icon="mdi-magnify"
          :label="$t('search')"
          single-line
          hide-details
          dense
      ></v-text-field>
      </v-col>
      <v-col cols="6" sm="4">
        <v-select
          class=" mt-3"
          multiple
          v-model="company_type"
          :items="[
                {id: 1, name: $t('company_with_contract')},
                {id: 0, name: $t('company_without_contract')},
                {id: 2, name: translate('Сала за ручавање')},
                {id: 3, name: translate('Б2Б')},
                {id: 4, name: $t('worker_more_restaurants')},
            ]"
          :item-text="item => $t(item.name)"
          item-value="id"
          :label="$t('firm') + ' ' + $t('type').toLowerCase()"
      ></v-select>
      </v-col>
      <v-col cols="4" sm="1" class="dim_margin">
        <v-btn title="Pretraga korisnika po ID" style="border-radius: 9px" outlined color="orange darken-4" class="mt-4" small @click.prevent="openModalSearchUserById()">
        <v-icon>mdi mdi-store-search-outline</v-icon>
      </v-btn>
      </v-col>
      <v-col cols="4" sm="1" class="dim_margin">
        <v-btn :style="'border-radius: 8px;border: 1px solid ' + $store.state.restaurant.settings.topbar.background + ';background: none;color: ' + $store.state.restaurant.settings.topbar.background + ';box-shadow: none;text-transform: capitalize'"
           dark
           class="mb-2 mt-4 mr-5"
           v-bind="attrs"
           v-on="on"
           small
           @click="print('print')" >
          <v-icon>mdi-printer</v-icon>
        </v-btn>
      </v-col>
      <v-col cols="4" sm="2" class="dim_margin" >
        <v-dialog v-model="dialog" max-width="650px" scrollable>
          <template v-slot:activator="{ on, attrs }">
            <v-btn :style="'border-radius: 8px;border: 1px solid ' + $store.state.restaurant.settings.topbar.background + ';background: none;color: ' + $store.state.restaurant.settings.topbar.background + ';box-shadow: none;text-transform: capitalize'"
                   dark
                   class="mt-4"
                   v-bind="attrs"
                   v-on="on"
                   small
            >
              <v-icon>mdi-plus</v-icon>
              {{$t("add_company")}}
            </v-btn>
          </template>
          <v-card>
            <v-card-title class="primary" style="display: flex;justify-content: space-between">
              <span class="headline white--text">{{ formTitle }} | ID: {{ editedItem.id }}</span>
              <v-btn color="primary px-5" text @click="close"><v-icon style="color: #fff">mdi-close</v-icon> </v-btn>
            </v-card-title>
            <v-card-text>
              <v-container style="padding-bottom: 10px !important;">
                <v-row>
                  <v-row class="okolo">
                    <v-col sm="12" lg="6">
                      <v-switch
                          v-model="editedItem.state"
                          inset
                          :label="$t('active')"
                          :false-value="0"
                          :true-value="1"
                          dense
                      ></v-switch>
                    </v-col>
                    <v-col sm="12" lg="6">
                      <v-switch
                          v-model="editedItem.is_credit"
                          inset
                          :label="$t('credit_only')"
                          :false-value="0"
                          :true-value="1"
                          dense
                      ></v-switch>
                      <!--                  <v-switch-->
                      <!--                      v-model="editedItem.contract"-->
                      <!--                      inset-->
                      <!--                      :label="$t('company_with_contract')"-->
                      <!--                      :false-value="0"-->
                      <!--                      :true-value="1"-->
                      <!--                      dense-->
                      <!--                  ></v-switch>-->
                    </v-col>
                  </v-row>
                  <v-row class="okolo">
                    <v-col sm="12" lg="6">
                      <v-switch
                          v-model="editedItem.checkReport"
                          inset
                          :label="$t('can_see_report_taken_meals')"
                          :false-value="0"
                          :true-value="1"
                          dense
                      ></v-switch>
                    </v-col>
                    <v-col sm="12" lg="6">
                      <v-radio-group v-model='editedItem.contract'>
                        <v-radio :value="1" :label="$t('company_with_contract')"></v-radio>
                        <v-radio :value="0" :label="$t('company_without_contract')"></v-radio>
                        <v-radio :value="2" :label="translate('Сала за ручавање')"></v-radio>
                        <v-radio :value="3" :label="translate('Б2Б')"></v-radio>
                        <v-radio :value="4" :label="$t('worker_more_restaurants')"></v-radio>
                      </v-radio-group>
                    </v-col>
                  </v-row>
                  <v-col sm="12" lg="12">
                    <v-switch :title="'Izabranim firmama i radnicima omoguci narucivanje mimo vremensmog limita'"
                              v-if="$store.state.restaurant && $store.state.restaurant.settings.no_time_limit"
                              v-model="editedItem.no_time_limit"
                              inset
                              :label="$t('no_time_limit')"
                              :false-value="0"
                              :true-value="1"
                              dense
                    ></v-switch>
                  </v-col>
                  <v-col cols="12">
                    <v-text-field
                        v-model="editedItem.name"
                        :rules="[$t('mandatory_field')]"
                        :label="$t('*title')"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12">
                    <v-select
                        v-model="editedItem.type"
                        :items="meniType"
                        :item-text="item => $t(item.name)"
                        item-value="name"
                        :label="$t('available_menu_type_for_comp')"
                        :rules="[$t('mandatory_field')]"
                    ></v-select>
                  </v-col>
                  <v-col cols="12">
                    <v-checkbox
                        @click="changeEveryDayOffer()"
                        v-model="editedItem.everyday_offer"
                        :label="translate('Доступно за наручивање јела/производа из свакодневне понуде')"
                        hide-details>
                    </v-checkbox>
                  </v-col>
                  <v-col v-if="desserts.length" cols="12">
                    <v-select
                        v-model="editedItem.parent"
                        :items="[{name:'',id:null}].concat(desserts.filter((item) => item.id))"
                        :label="$t('parent_company')"
                        item-text="name"
                        item-id="id"
                        return-object
                    ></v-select>
                  </v-col>
                  <v-col cols="12">
                    <v-text-field
                        v-model="editedItem.city"
                        :label="$t('city')"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12">
                    <v-text-field
                        v-model="editedItem.address"
                        :label="$t('address')"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12">
                    <v-text-field
                        v-model="editedItem.phone"
                        :label="$t('phone')"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12">
                    <v-text-field
                        v-model="editedItem.email"
                        :label="$t('email')"
                    ></v-text-field>
                  </v-col>
                  <v-row class="px-3">
                    <v-col cols="6">
                      <v-text-field
                          v-model="editedItem.lib"
                          :label="$t('jib')"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="6">
                      <v-text-field
                          v-model="editedItem.pib"
                          :label="$t('pib')"
                      ></v-text-field>
                    </v-col>
                  </v-row>
                  <!--                  <v-col cols="12" class="font-weight-bold pl-1 pb-0 mb-0">-->
                  <!--                    Локација (координате):-->
                  <!--                  </v-col>-->
                  <!--                  <v-row class="pl-2 pr-2">-->
                  <!--                    <v-col cols="6">-->
                  <!--                      <v-text-field-->
                  <!--                          v-model="editedItem.lat"-->
                  <!--                          :label="translate('LAT')"-->
                  <!--                          type="number"-->
                  <!--                      ></v-text-field>-->
                  <!--                    </v-col>-->
                  <!--                    <v-col cols="6">-->
                  <!--                      <v-text-field-->
                  <!--                          v-model="editedItem.long"-->
                  <!--                          :label="translate('LONG')"-->
                  <!--                          type="number"-->
                  <!--                      ></v-text-field>-->
                  <!--                    </v-col>-->
                  <!--                  </v-row>-->
                  <v-col cols="12">
                    <v-textarea
                        class="polje"
                        v-model="editedItem.description"
                        :label="$t('description')"
                    ></v-textarea>
                  </v-col>
                  <v-col cols="12">
                    <b>{{ $t("mark_shift_comp_recieves_meals") }}</b> <br><br>
                    <v-col cols="12">
                      <v-row>
                        <v-col cols="7">
                          <v-row style="align-content: center">
                            <b>
                              <span v-if="editedItem.change_shift_label">{{ translate('Доручак') }}</span>
                              <span v-else>{{ $t("shit_one") }}</span>
                            </b>
                            <v-radio-group style="margin-top: 1px;margin-left: 35px" v-model="editedItem.shift_one" row>
                              <v-radio :label="$t('yes')" :value="1"></v-radio>
                              <v-radio :label="$t('closed')" :value="0"></v-radio>
                            </v-radio-group>
                          </v-row>
                          <v-row style="align-content: center">
                            <b>
                              <span v-if="editedItem.change_shift_label">{{ translate('Ручак') }}</span>
                              <span v-else>{{ $t("shit_two") }}</span>
                            </b>
                            <v-radio-group style="margin-top: 1px;margin-left: 30px" v-model="editedItem.shift_two" row>
                              <v-radio :label="$t('yes')" :value="2"></v-radio>
                              <v-radio :label="$t('closed')" :value="0"></v-radio>
                            </v-radio-group>
                          </v-row>
                          <v-row style="align-content: center">
                            <b>
                              <span v-if="editedItem.change_shift_label">{{ translate('Вечера') }}</span>
                              <span v-else>{{ $t("shit_three") }}</span>
                            </b>
                            <v-radio-group style="margin-top: 1px;margin-left: 30px" v-model="editedItem.shift_three" row>
                              <v-radio :label="$t('yes')" :value="3"></v-radio>
                              <v-radio :label="$t('closed')" :value="0"></v-radio>
                            </v-radio-group>
                          </v-row>
                        </v-col>
                        <v-col cols="5" style="padding-top: 0;margin-top: 0">
                          <!--                    @change="setCredit(item, index)"-->
                          <!-- change_shift_label -->
                          <v-checkbox
                              v-model="editedItem.is_translate_shift"
                              :label="translate('Промјени називе смјена')"
                              hide-details
                          >
                          </v-checkbox>
                          <span style="display: block;padding-top: 10px;padding-left: 15px;color: #252525">{{ translate('1. -> Доручак') }}</span>
                          <span style="display: block;padding-left: 15px;color: #252525">{{ translate('2. -> Ручак') }}</span>
                          <span style="display: block;padding-left: 15px;color: #252525">{{ translate('3. -> Вечера') }}</span>
                        </v-col>
                      </v-row>
                    </v-col>
                  </v-col>
                  <v-col cols="12">
                    <a href="" @click.prevent="openModalSettings = true" style="text-decoration: none">
                      <v-icon :title="$t('settings')" small class="mr-2"> mdi-cog-outline</v-icon>{{ translate('Остала подешавања фирме') }}
                    </a><br><br>
                    <a href="" @click.prevent="openPartner(editedItem)" style="text-decoration: none">
                      <v-icon :title="$t('settings')" small class="mr-2"> mdi-handshake</v-icon>{{ translate('Партнерски ресторани и подешавања') }}
                    </a>
                  </v-col>
                </v-row>
              </v-container>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn  color="primary" text @click="close">
                {{ $t("cancel") }}
              </v-btn>
              <v-btn style="border-radius: 10px" color="primary" dark @click="save">
                {{ $t("save") }}
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-col>
    </v-row>

    <v-dialog v-model="dialogDelete" max-width="500px">
      <v-card>
        <v-card-title class="headline">{{
          $t("delete_confirmation")
        }}</v-card-title>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="closeDelete"
            >{{ $t("cancel") }}</v-btn
          >
          <v-btn color="blue darken-1" text @click="deleteItemConfirm"
            >{{ $t("confirm") }}</v-btn
          >
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-toolbar>
  <v-data-table style="margin-top: 5px"
    :headers="headers"
    :items="filterCompanies"
    :search="search"
    sort-by="calories"
    class="elevation-1"
    id="print"
    dense
    show-expand
    mobile-breakpoint="0"
  >
    <template v-for="header in headers" v-slot:[`header.${header.value}`]="{ header }"  >
      {{$t(header.text)}}
    </template>
    <template v-for="values in headers" v-slot:[`item.${values.value}`]="{ item }">
      {{translate(item[values.value])}}
    </template>
    <template v-slot:item.data-table-expand="{ item, isExpanded, expand }">
      <template v-if="item.children && item.children.length">
        <v-btn :style="'border-radius: 8px;border: 1px solid ' + $store.state.restaurant.settings.topbar.background + ';background: none;color: ' + $store.state.restaurant.settings.topbar.background + ';box-shadow: none;text-transform: capitalize'"
          dark
          small
          rounded
          @click="expand(true)"
          v-if="!isExpanded"
        >
        <v-icon>mdi-arrow-down</v-icon>
          {{ $t("branches") }}
        </v-btn>
        <v-btn color="primary" dark small rounded @click="expand(false)" v-else>
          <v-icon>mdi-arrow-up</v-icon>
          {{ $t("hide") }}
        </v-btn>
      </template>
    </template>

    <template v-slot:expanded-item="{ item, isExpanded, expand }">
      <template v-if="item.children">
        <td :colspan="headers.length" class="pa-0">
          <v-data-table
            dense
            :headers="headers"
            class="elevation-1 grey lighten-4"
            :items="item.children || []"
            :items-per-page="item.children.length"
            hide-default-footer
            hide-default-header
            mobile-breakpoint="0"
          >
            <v-spacer></v-spacer>
            <template v-slot:item.is_credit="{ item, index }">
              <div v-if="$store.state.user.type == 'restaurant' || $store.state.user.type == 'admin'" class="text-right">
                  <span class="d-flex">
                    <v-checkbox v-model="item.is_credit" @change="setCredit(item, index)"></v-checkbox>
                    <v-icon small @click="openCredit(item)" style="color: #1e7e34;margin-top: -14px">mdi-currency-eur</v-icon>
                  </span>
              </div>
            </template>
            <template v-slot:item.name="{ item, index }">
              {{ translate(item.name) }}
            </template>
            <template v-slot:item.type="{ item, index }">
              {{ $t(item.type) }}
            </template>
            <template v-slot:item.actions="{ item, index }">
              <v-btn
                small
                color="primary"
                text
                class="mx-2"
                :to="{ name: 'firma-id-korisnici', params: { id: item.id, name:item.name, parent_id:item.parent_id } }"
              >
                {{ $t("users") }} {{ item.users_count }}
              </v-btn>
              <v-icon small class="mr-2" @click="editItem(item)">mdi-pencil</v-icon>
              <v-icon :title="$t('settings')" small class="mr-2" @click="openSettings(item)"> mdi-cog-outline</v-icon>
              <v-icon small @click="deleteItem(item)"> mdi-delete </v-icon>
            </template>
          </v-data-table>
        </td>
      </template>
    </template>
    <template v-slot:item.is_credit="{ item, index }">
      <div v-if="$store.state.user.type == 'restaurant' || $store.state.user.type == 'admin'" class="text-right">
        <span class="d-flex">
          <v-checkbox v-model="item.is_credit" @change="setCredit(item, index)"></v-checkbox>
          <v-icon small @click="openCredit(item)" style="color: #1e7e34;margin-top: -14px">mdi-currency-eur</v-icon>
        </span>
      </div>
    </template>
    <template v-slot:item.type="{ item, index }">
      {{ $t(item.type) }}
    </template>
    <template v-slot:item.actions="{ item }">
      <v-btn
        small
        color="primary"
        text
        class="mx-2"
        :to="{ name: 'firma-id-korisnici', params: { id: item.id,name:item.name, parent_id:item.parent_id, children: item.children } }"
        v-if="
          (item.children && (item.children.length == 0 || item.children.length > 0)) ||
          !item.hasOwnProperty('children')
        "
      >
        {{ $t("users") }} {{ item.users_count }}
      </v-btn>
      <v-btn
        small
        color="primary"
        text
        class="mx-2"
        :to="{ name: 'firma-id-korisnici', params: { id: item.id,name:item.name, children: item.children } }"
        v-else
      >
        {{ $t("users") }}
        {{
          (item.children || []).reduce((sum, item) => {
            return item.users_count ? sum + item.users_count : sum;
          }, 0)
        }}
      </v-btn>
      <v-icon :title="$t('edit')" small class="mr-2" @click="editItem(item)"> mdi-pencil </v-icon>
      <v-icon :title="$t('settings')" small class="mr-2" @click="openSettings(item)"> mdi-cog-outline</v-icon>
      <v-icon :title="$t('Partner')" small class="mr-2" @click="openPartner(item)"> mdi-handshake </v-icon>
      <v-icon :title="$t('delete')" small @click="deleteItem(item)"> mdi-delete </v-icon>
    </template>
    <template v-slot:no-data>
      <v-btn :style="'border-radius: 8px;border: 1px solid ' + $store.state.restaurant.settings.topbar.background + ';background: none;color: ' + $store.state.restaurant.settings.topbar.background + ';box-shadow: none;text-transform: capitalize'" @click="initialize">
        {{ $t("reset") }}
      </v-btn>
    </template>
  </v-data-table>
  <v-dialog v-model="openModalAddCredit" max-width="700px" scrollable>
    <v-card>
      <v-card-title class="primary" style="width: 100%">
        <span style="font-size: 20px !important; justify-content: space-between;width: 100%" class="headline white--text text-center d-flex">
          {{ canSeeStoredCredit ? $t("credit") + ' ' + (modalCreditItems.company_id ? '| ID: ' +  modalCreditItems.id : '') : $t("add_credit") }}
          <span>
             <v-btn :style="canSeeStoredCredit ? 'background:#fff; color:#fb6d3b' : ''" style="border: 1px solid #fff;padding: 6px;border-radius: 5px" color="blue darken-1" text @click="editNewCredit()">
              <span :style="canSeeStoredCredit ? 'color:#fb6d3b' : 'color: #fff'" style="color: #fff;font-size: 11px;">{{ $t("edit_credit") }}</span>
            </v-btn>
            <v-btn :style="! canSeeStoredCredit ? 'background:#fff' : ''" class="ml-3" color="primary" style="color: #fff;border: 1px solid #fff;padding: 6px;border-radius: 5px" dark text @click="addNewCredit()">
              <span :style="! canSeeStoredCredit ? 'color:#fb6d3b' : 'color: #fff'" style="color: #fff;font-size: 11px;">{{ $t("add_credit1") }}</span>
            </v-btn>
          </span>
        </span>
        <v-spacer></v-spacer>
      </v-card-title>
      <v-card-text>
<!--        <p v-if="canSeeStoredCredit" style="font-size: 17px" class="mb-5 mt-3">{{ $t('credit') }}</p>-->
        <v-select class="mt-8"
          v-if="canSeeStoredCredit"
          :label="$t('credit')"
          v-model="credit"
          :items="storedCredits"
          hide-details
          outlined
          v-on:change="selectCredit"
          dense
          item-value="id"
          item-text="name"
        ></v-select>
        <div v-if="canSeeInputFields">
          <v-text-field class="mt-6"
              v-model="modalCreditItems.naziv"
              :label="$t('add_title')"
          ></v-text-field>
          <v-row>
            <v-col>
              <v-switch
                v-model="modalCreditItems.percent"
                inset
                :label="$t('percent')"
              ></v-switch>
            </v-col>
            <v-col>
              <v-text-field
                type="number"
                v-model="modalCreditItems.rabat"
                :label="$t('add_rabat')"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <v-menu
                  ref="menu"
                  v-model="menu"
                  :close-on-content-click="false"
                  :return-value.sync="modalCreditItems.date_from"
                  transition="scale-transition"
                  offset-y
                  max-width="290px"
                  min-width="290px"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                      v-model="modalCreditItems.date_from"
                      :label="$t('*inital_date')"
                      prepend-icon="mdi-calendar"
                      readonly
                      v-bind="attrs"
                      v-on="on"
                  ></v-text-field>
                </template>
                <v-date-picker
                    v-model="modalCreditItems.date_from"
                    no-title
                    scrollable
                >
                  <v-spacer></v-spacer>
                  <v-btn text color="primary" @click="menu = false">
                    {{ $t('cancel') }}
                  </v-btn>
                  <v-btn
                      text
                      color="primary"
                      @click="$refs.menu.save(modalCreditItems.date_from)">
                    OK
                  </v-btn>
                </v-date-picker>
              </v-menu>
            </v-col>
            <v-col>
              <v-menu
                  ref="menu1"
                  v-model="menu1"
                  :close-on-content-click="false"
                  :return-value.sync="modalCreditItems.date_to"
                  transition="scale-transition"
                  offset-y
                  max-width="290px"
                  min-width="290px"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                      v-model="modalCreditItems.date_to"
                      :label="$t('ending_date')"
                      prepend-icon="mdi-calendar"
                      readonly
                      v-bind="attrs"
                      v-on="on"
                  ></v-text-field>
                </template>
                <v-date-picker
                    v-model="modalCreditItems.date_to"
                    no-title
                    scrollable
                >
                  <v-spacer></v-spacer>
                  <v-btn text color="primary" @click="menu1 = false">
                    {{ $t('cancel') }}
                  </v-btn>
                  <v-btn
                      text
                      color="primary"
                      @click="$refs.menu1.save(modalCreditItems.date_to)">
                    OK
                  </v-btn>
                </v-date-picker>
              </v-menu>
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <v-switch
                  v-model="modalCreditItems.active"
                  inset
                  :label="$t('status')"
              ></v-switch>
            </v-col>
          </v-row>
        </div>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="blue darken-1" text @click="openModalAddCredit = false">
          <span style="color: #fb6d3b">{{ $t("cancel") }}</span>
        </v-btn>
        <v-btn color="primary" style="background: #fb6d3b;color: #fff" dark text @click="storeCredit()">
          <span style="color: #fff">{{ $t("save") }}</span>
        </v-btn>
        <v-spacer></v-spacer>
      </v-card-actions>
    </v-card>
  </v-dialog>
  <v-dialog v-model="openModalSettings" max-width="80%" scrollable>
    <v-card>
      <v-card-title class="primary" style="width: 100%;">
        <div style="display: flex;justify-content: space-between;width: 100%;">
        <span style="font-size: 20px !important; justify-content: space-between;width: 100%" class="headline white--text text-center d-flex">
          {{ $t('settings')}} | {{ firm.id ? firm.name : editedItem.name }} | {{ 'ID: ' + firm.id ? firm.id : editedItem.id }}
        </span>
        <v-btn color="primary px-5" text @click="closeModalSettings"><v-icon style="color: #fff">mdi-close</v-icon> </v-btn>
        </div>
        <v-spacer></v-spacer>
      </v-card-title>
      <v-card-text>
        <div style="margin-top: 30px">
          <v-switch
            v-model="firm.settings.confirm_delivery"
            inset
            :label="$t('automatic_delivery_no_confirm_company')"
          ></v-switch>
       </div>
        <hr>
        <div style="margin-top: 30px">
          <v-switch
            v-model="firm.settings.can_see_report"
            inset
            :label="$t('can_see_report')"
          ></v-switch>
        </div>
        <hr>
        <div style="margin-top: 30px">
          <v-switch
              v-model="firm.settings.can_see_ordered"
              inset
              :label="$t('can_see_ordered')"
          ></v-switch>
        </div>
        <hr>
        <div style="margin-top: 30px">
          <v-switch
              v-model="firm.settings.all_cooperation"
              inset
              :label="$t('allow_meal_to_partner')"
          ></v-switch>
        </div>
        <hr>
        <div style="margin-top: 20px">
          <v-switch
              v-model="firm.settings.worker"
              inset
              :label="$t('enable_meal_pickup_another_branch')"
          ></v-switch>
        </div>
        <hr>
        <div style="margin-top: 30px">
          <v-checkbox
              v-model="firm.settings.show_price"
              @click="changeShowPrice()"
              :label="translate($t('view')) + ' ' + translate($t('price')) + ' ' +  translate($t('order_meal'))"
          ></v-checkbox>
        </div>
        <hr v-if="firm && firm.type == 'table'">
        <div v-if="firm && firm.type == 'table'" >
          <span style="margin-top: 10px;padding-left: 10px;display: block;width: 100%;color: #000"> {{ translate('Удаљеност после које је онемогућено наручивање у ресторану') }}</span>
          <div style="display: flex">
            <div style="width: 10%">
              <v-text-field style="padding: 0;margin: 0"
                  type="number"
                  v-model="firm.settings.table_distance"
              ></v-text-field>
            </div>
            <span style="margin-top: 15px;padding-left: 5px;color: #000"> m</span>
          </div>
        </div>
        <hr v-if="firm.settings.order_from_one_shift_to_another">
        <div :style="! firm.settings.order_from_one_shift_to_another ? 'border:1px solid #cfcfcf;padding:8px' : ''">
        <div style="margin-top: 30px;">
          <v-switch
              @change="changeOrderMealFromOneShiftToAnother()"
              v-model="firm.settings.order_from_one_shift_to_another"
              inset
              :label="$t('order_meal_from_one_shift_to_another')"
          ></v-switch>
        </div>
        <hr v-if="! firm.settings.current_shift_meal && firm.settings.order_from_one_shift_to_another">
        <div style="margin-top: 30px;padding-left: 25px;" v-if="! firm.settings.order_from_one_shift_to_another">
          <v-switch
              @change="changeCurrentShiftMeal()"
              v-model="firm.settings.current_shift_meal"
              inset
              :label="$t('role') + ' ' + $t('current_shift_meal')"
          ></v-switch>
        </div>
        <div style="margin-top: 30px;padding-left: 25px" v-if="! firm.settings.current_shift_meal && ! firm.settings.order_from_one_shift_to_another">
          <v-switch
              v-model="firm.settings.order_from_one_shift_to_another_can"
              inset
              :label="translate('Аутоматско препознавање смене при додјели оброка')"
          ></v-switch>
        </div>
        <div style="padding-left: 25px" v-if="firm.settings.order_from_one_shift_to_another_can && ! firm.settings.current_shift_meal && ! firm.settings.order_from_one_shift_to_another">
          <p style="font-weight: 500;font-size: 14px;margin-top: 20px">{{ $t("order_meal_from_desc")}}</p>
          <p style="font-weight: 700;font-size: 15px;margin-top: -15px">{{ $t('delivery_shift')}}</p>
          <div v-if="firm.shift_one">
              <v-row>
                  <v-col lg="2" sm="12">
                      <p style="padding-top: 15px" class="font-weight-normal">
                          {{ $t('shift_one') }}*
                          <span v-if="firm.delivery_from_hour && firm.delivery_to_hour" style="padding-top: 22px;font-size: 10px" class="font-weight-bold">({{ firm.delivery_from_hour + ':' + firm.delivery_from_min }} - {{ firm.delivery_to_hour + ':' + firm.delivery_to_min }})</span></p>
                  </v-col>
                  <v-col lg="10" sm="10">
                      <v-row>
                          <v-col lg="3" sm="3">
                              <v-select class="vreme"
                                        :items="delivery_from"
                                        item-value="val"
                                        v-model="firm.settings.shift_one.delivery_from_hour"
                                        :label="$t('starting_from_h')"
                                        outlined
                              >
                              </v-select>
                          </v-col>
                          <v-col lg="3" sm="3">
                              <v-select class="vreme"
                                        :items="delivery_to"
                                        item-value="val"
                                        v-model="firm.settings.shift_one.delivery_from_min"
                                        :label="$t('starting_from_min')"
                                        outlined
                              >
                              </v-select>
                          </v-col>
                          <v-col lg="3" sm="3">
                              <v-select class="vreme"
                                        :items="delivery_from"
                                        item-value="val"
                                        v-model="firm.settings.shift_one.delivery_to_hour"
                                        :label="$t('up_to_h')"
                                        outlined
                              >
                              </v-select>
                          </v-col>
                          <v-col lg="3" sm="3">
                              <v-select class="vreme"
                                        :items="delivery_to"
                                        item-value="val"
                                        v-model="firm.settings.shift_one.delivery_to_min"
                                        :label="$t('up_to_min')"
                                        outlined
                              >
                              </v-select>
                          </v-col>
                      </v-row>
                  </v-col>
              </v-row>
          </div>
          <div v-if="firm.shift_two">
              <v-row>
                  <v-col lg="2" sm="12">
                      <p style="padding-top: 15px" class="font-weight-normal">
                          {{ $t('shift_two') }}*
                          <span v-if="firm.delivery_from_hour && firm.delivery_to_hour" style="padding-top: 22px;font-size: 10px" class="font-weight-bold">({{ firm.delivery_from_hour + ':' + firm.delivery_from_min }} - {{ firm.delivery_to_hour + ':' + firm.delivery_to_min }})</span></p>
                  </v-col>
                  <v-col lg="10" sm="10">
                      <v-row>
                          <v-col lg="3" sm="3">
                              <v-select class="vreme"
                                        :items="delivery_from"
                                        item-value="val"
                                        v-model="firm.settings.shift_two.delivery_from_hour"
                                        :label="$t('starting_from_h')"
                                        outlined
                              >
                              </v-select>
                          </v-col>
                          <v-col lg="3" sm="3">
                              <v-select class="vreme"
                                        :items="delivery_to"
                                        item-value="val"
                                        v-model="firm.settings.shift_two.delivery_from_min"
                                        :label="$t('starting_from_min')"
                                        outlined
                              >
                              </v-select>
                          </v-col>
                          <v-col lg="3" sm="3">
                              <v-select class="vreme"
                                        :items="delivery_from"
                                        item-value="val"
                                        v-model="firm.settings.shift_two.delivery_to_hour"
                                        :label="$t('up_to_h')"
                                        outlined
                              >
                              </v-select>
                          </v-col>
                          <v-col lg="3" sm="3">
                              <v-select class="vreme"
                                        :items="delivery_to"
                                        item-value="val"
                                        v-model="firm.settings.shift_two.delivery_to_min"
                                        :label="$t('up_to_min')"
                                        outlined
                              >
                              </v-select>
                          </v-col>
                      </v-row>
                  </v-col>
              </v-row>
          </div>
          <div style="margin-bottom: 20px" v-if="firm.shift_three">
                <v-row>
                    <v-col lg="2" sm="12">
                        <p style="padding-top: 15px" class="font-weight-normal">
                            {{ $t('shift_three') }}*
                            <span v-if="firm.delivery_from_hour && firm.delivery_to_hour" style="padding-top: 22px;font-size: 10px" class="font-weight-bold">({{ firm.delivery_from_hour + ':' + firm.delivery_from_min }} - {{ firm.delivery_to_hour + ':' + firm.delivery_to_min }})</span></p>
                    </v-col>
                    <v-col lg="10" sm="10">
                        <v-row>
                            <v-col lg="3" sm="3">
                                <v-select class="vreme"
                                          :items="delivery_from"
                                          item-value="val"
                                          v-model="firm.settings.shift_three.delivery_from_hour"
                                          :label="$t('starting_from_h')"
                                          outlined
                                >
                                </v-select>
                            </v-col>
                            <v-col lg="3" sm="3">
                                <v-select class="vreme"
                                          :items="delivery_to"
                                          item-value="val"
                                          v-model="firm.settings.shift_three.delivery_from_min"
                                          :label="$t('starting_from_min')"
                                          outlined
                                >
                                </v-select>
                            </v-col>
                            <v-col lg="3" sm="3">
                                <v-select class="vreme"
                                          :items="delivery_from"
                                          item-value="val"
                                          v-model="firm.settings.shift_three.delivery_to_hour"
                                          :label="$t('up_to_h')"
                                          outlined
                                >
                                </v-select>
                            </v-col>
                            <v-col lg="3" sm="3">
                                <v-select class="vreme"
                                          :items="delivery_to"
                                          item-value="val"
                                          v-model="firm.settings.shift_three.delivery_to_min"
                                          :label="$t('up_to_min')"
                                          outlined
                                >
                                </v-select>
                            </v-col>
                        </v-row>
                    </v-col>
                </v-row>
            </div>
        </div>
        </div>
        <hr style="margin-top: 20px" v-if="! firm.settings.order_from_one_shift_to_another && ! firm.settings.current_shift_meal">
<!--        <hr style="margin-top: 20px" v-if="! firm.settings.global_restaurant_time_settings">-->
        <div :style="firm.settings.global_restaurant_time_settings ? 'border:1px solid #cfcfcf;margin-top: 20px' : ''" style="padding: 8px">
          <div style="margin-top: 20px">
            <v-switch
              v-model="firm.settings.global_restaurant_time_settings"
              inset
              @change="changeTimeSettings"
              :label="$t('global_restaurant_time_settings')"
            ></v-switch>
          </div>
          <v-col cols="12" v-if="firm.settings.global_restaurant_time_settings">
            <p style="font-weight: 700;font-size: 13px;text-align: center;margin: 0">{{ $t('order_dead_line') }}</p>
          </v-col>
          <v-col cols="12" v-if="firm.settings.global_restaurant_time_settings" style="margin-bottom: 30px;margin-top: 20px">
            <v-row class="px-3" style="border-bottom: 1px solid #cfcfcf;display: flex;align-items: center;margin-bottom: 0;">
              <v-col style="text-align: center" class="py-0" cols="2">
                <label style="display:block;height:100%;line-height: 4.3; margin-right: 5px;background: rgba(251,109,59, 0.10);border-radius: 5px;" for="">{{ $t('monday') }}</label>
              </v-col>
              <v-col class="py-0" cols="2">
                <v-row style="margin-top: 10px;margin-right:5px">
                  <v-text-field :title="$t('day_before_limit')"
                    :label="$t('day_before_limit')"
                    type="number"
                    v-model.number="firm.settings.days.monday.day_before_limit">
                  </v-text-field>
                </v-row>
              </v-col>
              <v-col class="py-0 text-center" style="max-width: 2.3% !important;"><</v-col>
              <v-col style="background: rgba(251,109,59, 0.10);border-radius: 5px" class="py-0 px-8" cols="5">
                <v-row style="margin-top: 10px">
                  <v-select style="width: 20%"
                    :items="delivery_from_hour"
                    item-value="val"
                    v-model="firm.settings.days.monday.time.time_hour"
                    :label="$t('starting_from_h')"
                    outlined
                  >
                  </v-select>
                  <v-select style="margin-left: 10px;width: 20%"
                    :items="delivery_to_min"
                    item-value="val"
                    v-model="firm.settings.days.monday.time.time_minut"
                    :label="$t('up_to_min')"
                    outlined
                  >
                  </v-select>
                </v-row>
              </v-col>
              <v-col class="py-0 text-center" style="max-width: 2.3% !important;">></v-col>
              <v-col class="py-0" cols="2">
                <v-row style="margin-top: 10px;margin-left:0">
                  <v-text-field :title="$t('day_after_limit')"
                    :label="$t('day_after_limit')"
                    type="number"
                    v-model.number="firm.settings.days.monday.day_after_limit">
                  </v-text-field>
                </v-row>
              </v-col>
            </v-row>
            <v-row class="px-3" style="border-bottom: 1px solid #cfcfcf;display: flex;align-items: center;margin-bottom: 0">
              <v-col style="text-align: center" class="py-0" cols="2">
                <label style="display:block;height:100%;line-height: 4.3; margin-right: 5px;background: rgba(251,109,59, 0.10);border-radius: 5px;" for="">{{ $t('tuesday') }}</label>
              </v-col>
              <v-col class="py-0" cols="2">
                <v-row style="margin-top: 10px;margin-right:5px">
                  <v-text-field :title="$t('day_before_limit')"
                    :label="$t('day_before_limit')"
                    type="number"
                    v-model.number="firm.settings.days.tuesday.day_before_limit">
                  </v-text-field>
                </v-row>
              </v-col>
              <v-col class="py-0 text-center" style="max-width: 2.3% !important;"><</v-col>
              <v-col class="py-0 px-8" style="background: rgba(251,109,59, 0.10);border-radius: 5px" cols="5">
                <v-row style="margin-top: 10px">
                  <v-select style="width: 20%"
                    :items="delivery_from_hour"
                    item-value="val"
                    v-model="firm.settings.days.tuesday.time.time_hour"
                    :label="$t('starting_from_h')"
                    outlined
                  >
                  </v-select>
                  <v-select style="margin-left: 10px;width: 20%"
                    :items="delivery_to_min"
                    item-value="val"
                    v-model="firm.settings.days.tuesday.time.time_minut"
                    :label="$t('up_to_min')"
                    outlined
                  >
                  </v-select>
                </v-row>
              </v-col>
              <v-col class="py-0 text-center" style="max-width: 2.3% !important;">> </v-col>
              <v-col class="py-0" cols="2">
                <v-row style="margin-top: 10px;margin-left:0">
                  <v-text-field :title="$t('day_after_limit')"
                    :label="$t('day_after_limit')"
                    type="number"
                    v-model.number="firm.settings.days.tuesday.day_after_limit">
                  </v-text-field>
                </v-row>
              </v-col>
            </v-row>
            <v-row class="px-3" style="border-bottom: 1px solid #cfcfcf;display: flex;align-items: center;margin-bottom: 0">
              <v-col class="py-0 text-center" cols="2">
                <label style="display:block;height:100%;line-height: 4.3; margin-right: 5px;background: rgba(251,109,59, 0.10);border-radius: 5px;" for="">{{ $t('wednesday') }}</label>
              </v-col>
              <v-col class="py-0" cols="2">
                <v-row style="margin-top: 10px;margin-right:5px">
                  <v-text-field :title="$t('day_before_limit')"
                    :label="$t('day_before_limit')"
                    type="number"
                    v-model.number="firm.settings.days.wednesday.day_before_limit">
                  </v-text-field>
                </v-row>
              </v-col>
              <v-col class="py-0 text-center" style="max-width: 2.3% !important;"><</v-col>
              <v-col class="py-0 px-8" style="background: rgba(251,109,59, 0.10);border-radius: 5px" cols="5">
                <v-row style="margin-top: 10px">
                  <v-select style="width: 20%"
                    :items="delivery_from_hour"
                    item-value="val"
                    v-model="firm.settings.days.wednesday.time.time_hour"
                    :label="$t('starting_from_h')"
                    outlined
                  >
                  </v-select>
                  <v-select style="margin-left: 10px;width: 20%"
                    :items="delivery_to_min"
                    item-value="val"
                    v-model="firm.settings.days.wednesday.time.time_minut"
                    :label="$t('up_to_min')"
                    outlined
                  >
                  </v-select>
                </v-row>
              </v-col>
              <v-col class="py-0 text-center" style="max-width: 2.3% !important;">> </v-col>
              <v-col class="py-0" cols="2">
                <v-row style="margin-top: 10px;margin-left:0">
                  <v-text-field :title="$t('day_after_limit')"
                    :label="$t('day_after_limit')"
                    type="number"
                    v-model.number="firm.settings.days.wednesday.day_after_limit">
                  </v-text-field>
                </v-row>
              </v-col>
            </v-row>
            <v-row class="px-3" style="border-bottom: 1px solid #cfcfcf;display: flex;align-items: center;margin-bottom: 0">
              <v-col class="py-0 text-center" cols="2">
                <label style="display:block;height:100%;line-height: 4.3; margin-right: 5px;background: rgba(251,109,59, 0.10);border-radius: 5px;" for="">{{ $t('thursday')}}</label>
              </v-col>
              <v-col class="py-0" cols="2">
                <v-row style="margin-top: 10px;margin-right:5px">
                  <v-text-field :title="$t('day_before_limit')"
                    :label="$t('day_before_limit')"
                    type="number"
                    v-model.number="firm.settings.days.thursday.day_before_limit">
                  </v-text-field>
                </v-row>
              </v-col>
              <v-col class="py-0 text-center" style="max-width: 2.3% !important;"><</v-col>
              <v-col class="py-0 px-8" style="background: rgba(251,109,59, 0.10);border-radius: 5px" cols="5">
                <v-row style="margin-top: 10px">
                  <v-select style="width: 20%"
                    :items="delivery_from_hour"
                    item-value="val"
                    v-model="firm.settings.days.thursday.time.time_hour"
                    :label="$t('starting_from_h')"
                    outlined
                  >
                  </v-select>
                  <v-select style="margin-left: 10px;width: 20%"
                    :items="delivery_to_min"
                    item-value="val"
                    v-model="firm.settings.days.thursday.time.time_minut"
                    :label="$t('up_to_min')"
                    outlined
                  >
                  </v-select>
                </v-row>
              </v-col>
              <v-col class="py-0 text-center" style="max-width: 2.3% !important;">> </v-col>
              <v-col class="py-0" cols="2">
                <v-row style="margin-top: 10px;margin-left:0">
                  <v-text-field :title="$t('day_after_limit')"
                    :label="$t('day_after_limit')"
                    type="number"
                    v-model.number="firm.settings.days.thursday.day_after_limit">
                  </v-text-field>
                </v-row>
              </v-col>
            </v-row>
            <v-row class="px-3" style="border-bottom: 1px solid #cfcfcf;display: flex;align-items: center;margin-bottom: 0">
              <v-col class="py-0 text-center" cols="2">
                <label style="display:block;height:100%;line-height: 4.3; margin-right: 5px;background: rgba(251,109,59, 0.10);border-radius: 5px;" for="">{{ $t('friday') }}</label>
              </v-col>
              <v-col class="py-0" cols="2">
                <v-row style="margin-top: 10px;margin-right:5px">
                  <v-text-field :title="$t('day_before_limit')"
                    :label="$t('day_before_limit')"
                    type="number"
                    v-model.number="firm.settings.days.friday.day_before_limit">
                  </v-text-field>
                </v-row>
              </v-col>
              <v-col class="py-0 text-center" style="max-width: 2.3% !important;"><</v-col>
              <v-col class="py-0 px-8" style="background: rgba(251,109,59, 0.10);border-radius: 5px" cols="5">
                <v-row style="margin-top: 10px">
                  <v-select style="width: 20%"
                    :items="delivery_from_hour"
                    item-value="val"
                    v-model="firm.settings.days.friday.time.time_hour"
                    :label="$t('starting_from_h')"
                    outlined
                  >
                  </v-select>
                  <v-select style="margin-left: 10px;width: 20%"
                    :items="delivery_to_min"
                    item-value="val"
                    v-model="firm.settings.days.friday.time.time_minut"
                    :label="$t('up_to_min')"
                    outlined
                  >
                  </v-select>
                </v-row>
              </v-col>
              <v-col class="py-0 text-center" style="max-width: 2.3% !important;">> </v-col>
              <v-col class="py-0" cols="2">
                <v-row style="margin-top: 10px;margin-left:0">
                  <v-text-field :title="$t('day_after_limit')"
                    :label="$t('day_after_limit')"
                    type="number"
                    v-model.number="firm.settings.days.friday.day_after_limit">
                  </v-text-field>
                </v-row>
              </v-col>
            </v-row>
            <v-row class="px-3" style="border-bottom: 1px solid #cfcfcf;display: flex;align-items: center;margin-bottom: 0">
              <v-col class="py-0 text-center" cols="2">
                <label style="display:block;height:100%;line-height: 4.3; margin-right: 5px;background: rgba(251,109,59, 0.10);border-radius: 5px;" for="">{{ $t('saturday')}}</label>
              </v-col>
              <v-col class="py-0" cols="2">
                <v-row style="margin-top: 10px;margin-right:5px">
                  <v-text-field :title="$t('day_before_limit')"
                    :label="$t('day_before_limit')"
                    type="number"
                    v-model.number="firm.settings.days.saturday.day_before_limit">
                  </v-text-field>
                </v-row>
              </v-col>
              <v-col class="py-0 text-center" style="max-width: 2.3% !important;"><</v-col>
              <v-col class="py-0 px-8" style="background: rgba(251,109,59, 0.10);border-radius: 5px" cols="5">
                <v-row style="margin-top: 10px">
                  <v-select style="width: 20%"
                    :items="delivery_from_hour"
                    item-value="val"
                    v-model="firm.settings.days.saturday.time.time_hour"
                    :label="$t('starting_from_h')"
                    outlined
                  >
                  </v-select>
                  <v-select style="margin-left: 10px;width: 20%"
                    :items="delivery_to_min"
                    item-value="val"
                    v-model="firm.settings.days.saturday.time.time_minut"
                    :label="$t('up_to_min')"
                    outlined
                  >
                  </v-select>
                </v-row>
              </v-col>
              <v-col class="py-0 text-center" style="max-width: 2.3% !important;">> </v-col>
              <v-col class="py-0" cols="2">
                <v-row style="margin-top: 10px;margin-left:0">
                  <v-text-field :title="$t('day_after_limit')"
                    :label="$t('day_after_limit')"
                    type="number"
                    v-model.number="firm.settings.days.saturday.day_after_limit">
                  </v-text-field>
                </v-row>
              </v-col>
            </v-row>
            <v-row class="px-3" style="border-bottom: 1px solid #cfcfcf;display: flex;align-items: center;margin-bottom: 0">
              <v-col class="py-0 text-center" cols="2" >
                <label style="display:block;height:100%;line-height: 4.3; margin-right: 5px;background: rgba(251,109,59, 0.10);border-radius: 5px;" for="">{{ $t('sunday')}}</label>
              </v-col>
              <v-col class="py-0" cols="2">
                <v-row style="margin-top: 10px;margin-right:5px">
                  <v-text-field :title="$t('day_before_limit')"
                    :label="$t('day_before_limit')"
                    type="number"
                    v-model.number="firm.settings.days.sunday.day_before_limit">
                  </v-text-field>
                </v-row>
              </v-col>
              <v-col class="py-0 text-center" style="max-width: 2.3% !important;"><</v-col>
              <v-col class="py-0 px-8" style="background: rgba(251,109,59, 0.10);border-radius: 5px" cols="5">
                <v-row style="margin-top: 10px">
                  <v-select style="width: 20%" class="velicina-labele"
                    :items="delivery_from_hour"
                    item-value="val"
                    v-model="firm.settings.days.sunday.time.time_hour"
                    :label="$t('starting_from_h')"
                    outlined
                  >
                  </v-select>
                  <v-select style="margin-left: 10px;width: 20%" class="velicina-labele"
                    :items="delivery_to_min"
                    item-value="val"
                    v-model="firm.settings.days.sunday.time.time_minut"
                    :label="$t('up_to_min')"
                    outlined
                  >
                  </v-select>
                </v-row>
              </v-col>
              <v-col class="py-0 text-center" style="max-width: 2.3% !important;">> </v-col>
              <v-col class="py-0" cols="2">
                <v-row style="margin-top: 10px;margin-left:0">
                  <v-text-field :title="$t('day_after_limit')"
                    :label="$t('day_after_limit')"
                    type="number"
                    v-model.number="firm.settings.days.sunday.day_after_limit">
                  </v-text-field>
                </v-row>
              </v-col>
            </v-row>
          </v-col>
        </div>
        <v-divider v-if="user.type === 'admin'"></v-divider>
        <v-row no-gutters class="pt-4" v-if="user.type === 'admin'">
          <v-col cols="auto">
            <v-switch
              inset
              label="Ankete"
              hide-details
              class="mt-3 pr-4"
              v-model="hasQuestionare"
              @change="hasQuestionare ? questionares.push({qid: null, roles: []}) : questionares = []"
            ></v-switch>
          </v-col>
          <v-col>
            <v-row>
            <template v-for="(questionare, i) in questionares">
              <v-col cols="12">
                <v-row>
                  <v-col cols="3">
                    <v-text-field label="ID Ankete" v-model="questionare.qid" filled dense :disabled="!hasQuestionare" hide-details></v-text-field>
                  </v-col>
                  <v-col cols="7">
                    <v-combobox
                      v-model="questionare.roles"
                      hide-selected
                      label="Role"
                      multiple
                      persistent-hint
                      small-chips
                      hide-details
                      filled
                      dense
                      :items="['admin', 'guest', 'customer']"
                      :disabled="!hasQuestionare"
                    ></v-combobox>
                  </v-col>
                  <v-col cols="2">
                    <v-row v-if="i === questionares.length - 1" no-gutters justify="center" align="center" class="fill-height">
                      <v-btn icon color="orange" @click="questionares.splice(i, 1)" :disabled="!hasQuestionare">
                        <v-icon>mdi-minus</v-icon>
                      </v-btn>
                      <v-btn icon color="orange" @click="questionares.push({qid: null, roles: []})" :disabled="!hasQuestionare">
                        <v-icon>mdi-plus</v-icon>
                      </v-btn>
                    </v-row>
                  </v-col>
                </v-row>
              </v-col>
            </template>
          </v-row>
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="blue darken-1" text @click="closeModalSettings()">
          <span style="color: #fb6d3b">{{ $t("cancel") }}</span>
        </v-btn>
        <v-btn color="primary" style="background: #fb6d3b;color: #fff" dark text @click="storeFirmSettings()">
          <span style="color: #fff">{{ $t("save") }}</span>
        </v-btn>
        <v-spacer></v-spacer>
      </v-card-actions>
    </v-card>
  </v-dialog>
  <v-dialog v-model="openModalSearchUser" max-width="800px" scrollable>
    <v-card>
      <v-card-title class="primary" style="width: 100%;">
        <div style="display: flex;justify-content: space-between;width: 100%">
        <span style="font-size: 18px !important; justify-content: space-between;width: 100%" class="headline white--text text-center d-flex">
          {{ $t("users") }}
        </span>
        <v-btn color="primary px-5" text @click="openModalSearchUser = false"><v-icon style="color: #fff">mdi-close</v-icon> </v-btn>
        </div>
        <v-spacer></v-spacer>
      </v-card-title>
      <v-card-text>
        <div>
          <v-row justify="center" class="pt-5">
            <v-text-field
                class="px-4"
                type="number"
                v-model="search_user_id"
                :label="translate('* Унеси ИД корисника...')"
            ></v-text-field>
          </v-row>
          <p v-if="userByIdError" class="my-3" style="font-weight: bold">{{ userByIdError }}</p>
          <section v-if="search_user_id_data">
            <v-row justify="center">
              <v-col cols="5"> <qrcode-vue id="qrkodstampa" :value="JSON.stringify({
                id: selectedQr,
                secure: selectedQrSecret,
              })" :size="160" level="H" render-as="svg"/></v-col>
              <v-col cols="7">
                <div style="color:#000; !important;font-weight: 900;margin-left: -15px" class="p-3">
                  <div>ID: {{ search_user_id_data.data.id }}</div>
                  <div>{{ translate(search_user_id_data.data.name) + " " + translate(search_user_id_data.data.lastname) }}</div>
                  <div>{{ search_user_id_data.data.email }}</div>
                  <div>{{ search_user_id_data.data.phone }}</div>
                  <div>{{ $t('firm') }}:</div>
                  <div v-for="company in search_user_id_data.data.companies">
                    {{ company.name }}
                  </div>
                </div>
              </v-col>
            </v-row>
          </section>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="blue darken-1" text @click="openModalSearchUser = false">
              <span style="color: #fb6d3b">{{ $t("cancel") }}</span>
            </v-btn>
            <v-btn style="background: #fb6d3b;color: #fff" color="primary" dark text @click="searchUserById()">
              <span style="color: #fff">{{ $t("search") }}</span>
            </v-btn>
            <v-spacer></v-spacer>
          </v-card-actions>
        </div>
      </v-card-text>
    </v-card>
  </v-dialog>
  <v-dialog v-model="dialogPartner">
    <v-card>
      <v-card-title>
        <div style="display: flex;justify-content: space-between;width: 100%">
          <span>{{ selectedCompany ? selectedCompany.name + ' ID: ' + selectedCompany.id : '' }}</span>
          <v-btn @click="dialogPartner = false" icon>
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </div>
      </v-card-title>
      <v-card-text>
        <v-row>
          <v-col cols="6">
            <v-card>
              <v-card-title>Svi Restorani</v-card-title>
              <v-card-text style="height: 600px; overflow-y: scroll">
                <v-list-item-group
                  color="primary"
                >
                  <v-list-item
                    v-for="(restaurant, i) in restaurants"
                    :key="i"
                    dense
                    @click="selectedRestaurants.push({...restaurant, ...{times: [moment().format('HH:mm')]}}), restaurants.splice(i, 1)"
                  >
                    <v-list-item-content>
                      <v-list-item-title v-text="restaurant.name"></v-list-item-title>
                    </v-list-item-content>
                    <v-list-item-icon>
                      <v-icon>mdi-arrow-right</v-icon>
                    </v-list-item-icon>
                  </v-list-item>
                </v-list-item-group>
              </v-card-text>
            </v-card>
          </v-col>
          <v-col cols="6">
            <v-card>
              <v-card-title>Partneri Restorani</v-card-title>
              <v-card-text style="height: 600px; overflow-y: scroll">
                <v-list-item-group
                  color="primary"
                  v-if="selectedRestaurants.length"
                >
                  <v-list-item
                    v-for="(restaurant, i) in selectedRestaurants"
                    :key="i"
                    dense
                  >
                    <v-list-item-icon @click="selectedRestaurants.splice(i, 1), restaurants.unshift(restaurant)">
                      <v-icon>mdi-arrow-left</v-icon>
                    </v-list-item-icon>
                    <v-list-item-content>
                      <v-list-item-title v-text="restaurant.name"></v-list-item-title>
                    </v-list-item-content>
                    <v-list-item-icon @click="selectedRestaurant = restaurant, dialogTime = true">
                      <v-icon>mdi-dots-vertical</v-icon>
                    </v-list-item-icon>
                  </v-list-item>
                </v-list-item-group>
                <v-alert v-else type="info" outlined>
                  Please select restaurants
                </v-alert>
              </v-card-text>
            </v-card>
          </v-col>
          <v-col cols="12">
            <v-btn block color="primary" @click="savePartners">Save</v-btn>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </v-dialog>
  <v-dialog v-model="dialogTime" max-width="65%">
    <v-card>
      <h3 style="padding: 12px;display: flex;justify-content: space-between">
        <span>{{ selectedRestaurant ? selectedRestaurant.name : '' }} / {{ selectedCompany ? selectedCompany.name : ''}}</span>
        <v-btn @click="dialogTime = false" icon>
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </h3>
      <div>
        <v-card-title>
          <span class="headline">{{ translate('Места испоруке') }}</span>
          <v-spacer></v-spacer>
        </v-card-title>
        <v-card-text>
          <v-row>
            <v-col cols="3">
              <v-checkbox v-model="delivery_to_user_address" :label="translate('Достава на адресу')" hide-details></v-checkbox>
            </v-col>
            <v-col cols="3">
              <v-checkbox v-model="delivery_to_firm" :label="translate('Достава у фирму')" hide-details></v-checkbox>
            </v-col>
            <v-col cols="3">
             <v-checkbox v-model="restaurant_eat" :label="translate('Ручак у ресторану')" hide-details></v-checkbox>
            </v-col>
            <v-col cols="3">
              <v-checkbox v-model="take_by_user" :label="translate('Преузети сам')" hide-details></v-checkbox>
            </v-col>
          </v-row>
        </v-card-text>
      </div>
      <div>
        <v-card-title>
          <span class="headline">{{ translate('Подешавање термина') }}</span>
        </v-card-title>
        <v-card-text>
          <v-radio-group v-model="delivery_time"  @change="changeDeliverySettings(delivery_time)">
            <v-radio :label="translate('Корисник не може бирати термин/вријеме преузимања')" value="no_delivery_time"></v-radio>
            <v-radio :label="translate('Корисник задаје време преузмања')" value="user_delivery_time"></v-radio>
            <v-radio :label="translate('Корисник бира између дефинисаних термина за доставу')" value="choose_delivery_time"></v-radio>
          </v-radio-group>
        </v-card-text>
      </div>
      <div v-if="selectedRestaurant && delivery_time == 'choose_delivery_time'" class="mt-2">
        <v-card-title>
          <span class="headline">{{ translate('Термини за доставу') }}</span>
        </v-card-title>
        <v-card-text>
        <v-row v-if="selectedRestaurant">
          <template v-for="(time, i) in selectedRestaurant.times">
            <v-col cols="12">
              <v-row no-gutters>
                <v-text-field type="time" :name="i" hide-details filled v-model="selectedRestaurant.times[i]"></v-text-field>
                <v-btn @click="selectedRestaurant.times.splice(i, 1)" height="56" color="error"  v-if="selectedRestaurant.times.length - 1 == i" >Remove</v-btn>
                <v-btn @click="selectedRestaurant.times.push(moment().format('HH:mm'))" height="36" color="primary">Add</v-btn>
              </v-row>
            </v-col>
          </template>
        </v-row>
        <v-row v-if="selectedRestaurant.times.length == 0">
          <template>
            <v-col cols="12">
              <v-row no-gutters>
                <v-btn @click="selectedRestaurant.times.push(moment().format('HH:mm'))" height="36" color="primary">{{ $t('add') }}</v-btn>
              </v-row>
            </v-col>
          </template>
        </v-row>
      </v-card-text>
      </div>
      <div>
        <v-card-title>
          <span class="headline">{{ translate('Лимити') }}</span>
        </v-card-title>
        <v-card-text>
          <v-radio-group v-model="limits" @change="onChangeSetLimit()">
            <span class="mb-2" style="display: flex;justify-content: start;">
              <v-radio :label="translate('Оброк дневно')" value="obrok"></v-radio>
               <span v-if="limits == 'obrok'" class="text-left ml-5">
                 <v-text-field v-if="selectedRestaurant && selectedRestaurant.voucher && selectedRestaurant.voucher.params.limit.type == 'meals_per_day'"
                   :label="translate('Број оброка')"
                   type="number"
                   @change="setMealAmount()"
                   v-model.number="selectedRestaurant.voucher.params.limit.limits.meals"
                 >
                 </v-text-field>
              </span>
            </span>
<!--            <v-radio :label="translate('2 Оброка дневно')" value="obrok_2"></v-radio>-->
            <div class="mb-2">
              <v-radio :label="translate('Оброк по смјени - уколико смјена фирма ради (доручак, ручак, вечера)')" value="smena"></v-radio>
              <div v-if="limits == 'smena'" class="text-left ml-5" style="display: flex;justify-content: start;align-items: center">
                <div style="margin-right: 12px">
                 <v-text-field v-if="selectedRestaurant && selectedRestaurant.voucher && selectedRestaurant.voucher.params.limit.type == 'meals_per_shift'"
                   :label="translate('Број оброка по смени 1')"
                   type="number"
                   @change="setShiftLimit()"
                   v-model.number="selectedRestaurant.voucher.params.limit.limits[0].meals"
                 >
                 </v-text-field>
               </div>
                <div style="margin-right: 12px">
                 <v-text-field v-if="selectedRestaurant && selectedRestaurant.voucher && selectedRestaurant.voucher.params.limit.type == 'meals_per_shift'"
                   :label="translate('Број оброка по смени 2')"
                   type="number"
                   @change="setShiftLimit()"
                   v-model.number="selectedRestaurant.voucher.params.limit.limits[1].meals"
                 >
                 </v-text-field>
               </div>
                <div style="margin-right: 12px">
                   <v-text-field v-if="selectedRestaurant && selectedRestaurant.voucher && selectedRestaurant.voucher.params.limit.type == 'meals_per_shift'"
                     :label="translate('Број оброка по смени 3')"
                     type="number"
                     @change="setShiftLimit()"
                     v-model.number="selectedRestaurant.voucher.params.limit.limits[2].meals"
                   >
                   </v-text-field>
                 </div>
              </div>
            </div>
            <span class="mb-2" style="display: flex;justify-content: start;">
              <v-radio :label="translate('Сноси фирма дневно')" value="firma"></v-radio>
              <span v-if="limits == 'firma'" class="text-left ml-5">
                 <v-text-field v-if="selectedRestaurant && selectedRestaurant.voucher && selectedRestaurant.voucher.params.limit.type == 'amount'"
                     :label="translate('Износ')"
                     type="number"
                     @change="changeLimit()"
                     v-model.number="selectedRestaurant.voucher.params.limit.limits.amount">
                </v-text-field>
                <v-text-field v-else
                  :label="translate('Износ')"
                  type="number"
                  @change="setNewLimit()"
                  v-model.number="price">
                </v-text-field>
              </span>
            </span>
            <v-radio :label="translate('Корисник добија бонова колико има радних дана')" value="bon"></v-radio>
            <v-radio :label="translate('Студенти (ученици) купују бонова за мјесец колико има мјесец дана по сниженим цијенама (посебно за доручак, ручак, вечеру), Наручују док има бонова. Могу докупити бонове по тржишним цијенама')" value="student"></v-radio>
          </v-radio-group>
          <hr>
          <v-checkbox v-if="selectedRestaurant && selectedRestaurant.voucher" @click="changeCanOrderExtra()"
              v-model="selectedRestaurant.voucher.params.limit.can_order_extra"
              :label="translate('Радник може уз властиту доплату обавити наруџбу и изнад лимита који покрива фирму')"
              hide-details>
          </v-checkbox>
          <v-checkbox v-else-if="! limits && ! (selectedRestaurant && selectedRestaurant.voucher)" @click="changeCanOrderExtra()"
                      v-model="can_order_extra"
                      :label="translate('Радник може уз властиту доплату обавити наруџбу и изнад лимита који покрива фирму')"
                      hide-details>
          </v-checkbox>
        </v-card-text>
      </div>
      <v-col cols="12">
        <v-btn block color="primary" @click="dialogTime = false">{{ $t('close')}}</v-btn>
      </v-col>
    </v-card>
  </v-dialog>
</div>
</template>

<script>
import axios from "../plugins/axios";
import QrcodeVue from "qrcode.vue";
import moment from "moment";

export default {
  components: {QrcodeVue},
  data: () => ({
    moment,
    dialogTime: false,
    delivery_time: 'no_delivery_time',
    delivery_to_user_address: false,
    delivery_to_firm: false,
    restaurant_eat: false,
    take_by_user: false,
    dialogPartner: false,
    dialog: false,
    limits: false,
    price: 0,
    questionares: [],
    hasQuestionare: false,
    selectedCompany: null,
    firm: {
      settings: {
        current_shift_meal: false,
        days: {
          monday: {
            time: {
              time_hour: '',
              time_minut: '',
            },
            day_before_limit: '',
            day_after_limit: '',
            // change_week: ''
          },
          tuesday: {
            time: {
              time_hour: '',
              time_minut: '',
            },
            day_before_limit: '',
            day_after_limit: '',
            // change_week: ''
          },
          wednesday: {
            time: {
              time_hour: '',
              time_minut: '',
            },
            day_before_limit: '',
            day_after_limit: '',
            // change_week: ''
          },
          thursday: {
            time: {
              time_hour: '',
              time_minut: '',
            },
            day_before_limit: '',
            day_after_limit: '',
            // change_week: ''
          },
          friday: {
            time: {
              time_hour: '',
              time_minut: '',
            },
            day_before_limit: '',
            day_after_limit: '',
            // change_week: ''
          },
          saturday: {
            time: {
              time_hour: '',
              time_minut: '',
            },
            day_before_limit: '',
            day_after_limit: '',
            // change_week: ''
          },
          sunday: {
            time: {
              time_hour: '',
              time_minut: '',
            },
            day_before_limit: '',
            day_after_limit: '',
            // change_week: ''
          },
          day: {
            monday: '',
            tuesday: '',
            wednesday: '',
            thursday: '',
            friday: '',
            saturday: '',
            sunday: '',
          }
        },
        order_from_one_shift_to_another_can: true,
        global_restaurant_time_settings: false,
        confirm_delivery: false,
        can_see_ordered: false,
        order_from_one_shift_to_another: true,
        can_see_report: false,
        all_cooperation: false,
        no_time_limit: false,
        show_price: false,
        worker: false,
      }
    },
    delivery_from_hour: [
      {text: '00', val: 0},
      {text: '01', val: 1},
      {text: '02', val: 2},
      {text: '03', val: 3},
      {text: '04', val: 4},
      {text: '05', val: 5},
      {text: '06', val: 6},
      {text: '07', val: 7},
      {text: '08', val: 8},
      {text: '09', val: 9},
      {text: '10', val: 10},
      {text: '11', val: 11},
      {text: '12', val: 12},
      {text: '13', val: 13},
      {text: '14', val: 14},
      {text: '15', val: 15},
      {text: '16', val: 16},
      {text: '17', val: 17},
      {text: '18', val: 18},
      {text: '19', val: 19},
      {text: '20', val: 20},
      {text: '21', val: 21},
      {text: '22', val: 22},
      {text: '23', val: 23},
      {text: '24', val: 24},
    ],
    delivery_to_min: [
      {text: '00', val: 0},
      {text: '15', val: 15},
      {text: '30', val: 30},
      {text: '45', val: 45},
      {text: '59', val: 59},
    ],
    search: "",
    on: undefined,
    attrs: undefined,
    credit: '',
    menu_type: [],
    dialogDelete: false,
    openModalAddCredit: false,
    openModalSettings: false,
    openModalEditCredit: false,
    canSeeStoredCredit: true,
    canSeeInputFields: false,
    menu: null,
    menu1: null,
    modalEditCreditItems: '',
    modalCreditItems: {
      naziv: '',
      rabat: '',
      percent: '',
      date_from: null,
      date_to: null,
      active: '',
      date: '',
    },
    storedCredits: [],
    headers: [
      {
        text: "Id",
        align: "end",
        value: "id",
        //width: '25px'
      },
      {
        text: "title",
        align: "start",
        //width: '15%',
        value: "name",
      },
      {
        text: "address",
        align: "start",
        value: "address",
        // width: '15%',
        sortable: false,
      },
      // {
      //   text: "email",
      //   align: "start",
      //   //width: '15%',
      //   value: "email",
      //   sortable: false,
      // },
      {
        text: "credit_only",
        align: "start",
        //width: '15%',
        value: "is_credit",
        sortable: false,
      },
      {
        text: "menu_type",
        align: "center",
        value: "type",
        //width: '15%',
        sortable: false,
      },
      {
        text: "actions",
        align: "center",
        value: "actions",
        //width: '15%',
        sortable: false,
      },
      {
        text: "",
        //width: '15%',
        value: "data-table-expand",
      },
    ],
    meniType: '',
    meniTypeOrg: '',
    desserts: [],
    editedIndex: -1,
    editedParentIndex: -1,
    company_type: [],
    editedItem: {
      name: "",
      city: "",
      address: "",
      phone: "",
      email: "",
      lib: "",
      pib: "",
      description: "",
      state: 1,
      checkReport:0,
      no_time_limit:0,
      shift_one: 1,
      shift_two: 0,
      shift_three: 0,
      contract: 1,
      lat: '',
      long: '',
      type: 'small',
      change_shift_label: false,
      everyday_offer: false,
      is_translate_shift: false
    },
    defaultItem: {
      name: "",
      city: "",
      address: "",
      phone: "",
      email: "",
      lib: "",
      pib: "",
      description: "",
      state: 1,
      checkReport:0,
      no_time_limit:0,
      shift_one: 1,
      shift_two: 0,
      shift_three: 0,
      contract: 1,
      lat: '',
      long: '',
      type: 'small',
      change_shift_label: false,
      everyday_offer: false,
      is_translate_shift: false
    },
    user: '',
    delivery_from: [
      {text: '00', val: '00'},
      {text: '01', val: '01'},
      {text: '02', val: '02'},
      {text: '03', val: '03'},
      {text: '04', val: '04'},
      {text: '05', val: '05'},
      {text: '06', val: '06'},
      {text: '07', val: '07'},
      {text: '08', val: '08'},
      {text: '09', val: '09'},
      {text: '10', val: '10'},
      {text: '11', val: '11'},
      {text: '12', val: '12'},
      {text: '13', val: '13'},
      {text: '14', val: '14'},
      {text: '15', val: '15'},
      {text: '16', val: '16'},
      {text: '17', val: '17'},
      {text: '18', val: '18'},
      {text: '19', val: '19'},
      {text: '20', val: '20'},
      {text: '21', val: '21'},
      {text: '22', val: '22'},
      {text: '23', val: '23'},
      {text: '24', val: '24'},
    ],
    delivery_to: [
      {text: '00', val: '00'},
      {text: '15', val: '15'},
      {text: '30', val: '30'},
      {text: '45', val: '45'},
    ],
    openModalSearchUser: false,
    search_user_id: '',
    search_user_id_data: '',
    userByIdError: '',
    selectedQr: '',
    selectedQrSecret: '',
    restaurants: [],
    selectedRestaurants: [],
    selectedRestaurant: null,
    shift_one: 0,
    shift_two: 0,
    shift_three: 0,
    can_order_extra: false
  }),

  computed: {
    // meniType() {
    //   return [
    //     {text:this.$t('small'),val:'small'},
    //     {text:this.$t('large'),val:'large'},
    //     {text:this.$t('group'),val:'group'}
    //   ]
    // },
    filterCompanies() {
      let kompanije = ''
      if (this.company_type.length) {
        kompanije = []
        console.log(this.company_type)
        this.desserts.filter(item => {
          if (this.company_type.includes(item.contract)) {
            kompanije.push(item)
          }
        })
      } else {
        kompanije = this.desserts
      }
      return kompanije
    },
    formTitle() {
      return this.editedIndex === -1 ? this.$t('new_company') : this.$t('change_company');
    },
    getRole() {
      let usr = ''
      this.user.restaurants.filter(restoran => {
        restoran.users.filter(user => {
            if (user.id == this.user.id) {
              usr = user
            }
        })
      })
      return usr
    }
  },

  watch: {
    selectedRestaurant() {
      if(this.selectedRestaurant) {
        if (this.selectedRestaurant.voucher) {
          if (this.selectedRestaurant.voucher.params.limit.type == 'amount') {
            this.limits = 'firma'
          } else if (this.selectedRestaurant.voucher.params.limit.type == 'meals_per_day') {
            this.limits = 'obrok'
          }  else if (this.selectedRestaurant.voucher.params.limit.type == 'meals_per_shift') {
            this.limits = 'smena'
          }
        } else {
          this.limits = ''
        }
        if (this.selectedRestaurant.delivery_setting) {
          if (this.selectedRestaurant.delivery_setting.delivery_settings == 'no_delivery_time') {
            this.delivery_time = 'no_delivery_time'
          } else if (this.selectedRestaurant.delivery_setting.delivery_settings == 'user_delivery_time') {
            this.delivery_time = 'user_delivery_time'
          } else if (this.selectedRestaurant.delivery_setting.delivery_settings == 'choose_delivery_time') {
            this.delivery_time = 'choose_delivery_time'
          }
        } else {
          this.delivery_time = ''
        }
      }
    },
    dialog(val) {
      val || this.close();
    },
    dialogDelete(val) {
      val || this.closeDelete();
    },
    "editedItem.contract"(newParam, oldParam) {
      // if (newParam == 2) {
      //   this.meniType.push({
      //     name: this.$t("table"),
      //     ime: "table",
      //   })
      // } else {
      //   this.meniType.filter((item,index) => {
      //     if (item.ime && item.ime == 'table') {
      //       this.meniType.splice(index,1);
      //     }
      //   })
      // }
      // this.$forceUpdate()
    },
  },

  created() {
    this.initialize();
    this.user = this.$store.state.user;

    axios.get('/restaurants').then(res => {
      this.restaurants = res.data.map(i => {
        i.times = [this.moment().format('HH:mm')]
        return i
      })
    })
  },

  methods: {
    changeCanOrderExtra() {
      if (this.selectedRestaurant) {
        let canOrderExtra = this.can_order_extra ? this.can_order_extra : this.selectedRestaurant.voucher.params.limit.can_order_extra
        axios.post('/companies/set-company-can-order-extra', {
          vaucer: this.selectedRestaurant,
          can_order_extra: canOrderExtra,
          company: this.selectedCompany,
        }).then(({data}) => {
          this.selectedRestaurant.voucher = data
        })
      }
      this.$forceUpdate()
    },
    setShiftLimit() {
      if (this.selectedRestaurant) {
        let canOrderExtra = this.can_order_extra ? this.can_order_extra : this.selectedRestaurant.voucher.params.limit.can_order_extra
        axios.post('/companies/set-company-shift-limit', {
          vaucer: this.selectedRestaurant,
          company: this.selectedCompany,
          limit: this.limits,
          can_order_extra: canOrderExtra,
          price: this.price,
        }).then(({data}) => {
          this.selectedRestaurant.voucher = data
        })
      }
      this.$forceUpdate()
    },
    setMealAmount() {
      if (this.selectedRestaurant) {
        let canOrderExtra = this.can_order_extra ? this.can_order_extra : this.selectedRestaurant.voucher.params.limit.can_order_extra
        axios.post('/companies/set-company-meal-amount', {
          vaucer: this.selectedRestaurant,
          company: this.selectedCompany,
          limit: this.limits,
          can_order_extra: canOrderExtra,
          price: this.price,
        }).then(({data}) => {
          this.selectedRestaurant.voucher = data
        })
      }
      this.$forceUpdate()
    },
    changeDeliverySettings(delivery_time) {
      if (this.selectedRestaurant) {
        axios.post('/companies/change-delivery-settings', {
          delivery_settings: this.selectedRestaurant,
          company: this.selectedCompany,
          delivery: delivery_time
        }).then(({data}) => {
          this.selectedRestaurant.delivery_setting = data
        })
      }
      this.$forceUpdate()
    },
    onChangeSetLimit() {
      if (this.selectedRestaurant) {
        let canOrderExtra = this.can_order_extra ? this.can_order_extra : this.selectedRestaurant.voucher.params.limit.can_order_extra
        axios.post('/companies/set-company-limit', {
          vaucer: this.selectedRestaurant,
          company: this.selectedCompany,
          limit: this.limits,
          can_order_extra: canOrderExtra,
          price: this.price
        }).then(({data}) => {
          this.selectedRestaurant.voucher = data
        })
      }
      this.$forceUpdate()
    },
    setNewLimit() {
      if (this.selectedRestaurant) {
        let canOrderExtra = this.can_order_extra ? this.can_order_extra : this.selectedRestaurant.voucher.params.limit.can_order_extra
        axios.post('/companies/set-new-company-limit', {
          vaucer: this.selectedRestaurant,
          company: this.selectedCompany,
          price: this.price,
          can_order_extra: canOrderExtra
        }).then(({data}) => {
          this.selectedRestaurant.voucher = data
        })
      }
      this.$forceUpdate()
    },
    changeLimit() {
      if (this.selectedRestaurant) {
        let canOrderExtra = this.selectedRestaurant.voucher.params.limit.can_order_extra
        axios.post('/companies/change-company-limit', {
          voucher: this.selectedRestaurant.voucher,
          can_order_extra: canOrderExtra
        }).then(({data}) => {
          this.selectedRestaurant.voucher = data
        })
      }
      this.$forceUpdate()
    },
    changeTimeSettings() {
      this.$forceUpdate()
    },
    changeOrderMealFromOneShiftToAnother() {
      if (this.firm.settings.order_from_one_shift_to_another) {
        this.firm.settings.order_from_one_shift_to_another_can = false
        this.firm.settings.current_shift_meal = false
      } else {
        this.firm.settings.order_from_one_shift_to_another_can = true
      }
    },
    changeCurrentShiftMeal() {
      if (this.firm.settings.current_shift_meal) {
        this.firm.settings.order_from_one_shift_to_another_can = false
      } else {
        this.firm.settings.order_from_one_shift_to_another_can = true
      }
    },
    searchUserById() {
      this.search_user_id_data = ''
      axios.post('/user/search-user-by-id', { user_id: this.search_user_id }).then(({data}) => {
        if (data) {
          this.search_user_id = ''
          this.search_user_id_data = data
          this.selectedQrSecret = data.data.qrCode
          this.selectedQr = data.data.id;
          this.userByIdError = ''
        }
      }).catch((error) => {
        this.userByIdError = error.response.data.message
      });
    },
    openModalSearchUserById() {
      this.openModalSearchUser = true
    },
    loadQuestionnaire() {
      axios.get(`/companies/${this.selectedCompany.id}/questionnaire`)
        .then(res => {
          this.questionares = res.data && res.data.length ? res.data : []
          if(res.data && res.data.length) this.hasQuestionare = true
        })
    },
    changeEveryDayOffer() {
      if (this.editedItem.id) {
        axios.post('/companies/change-everyday-offer', this.editedItem).then(({data}) => {
          if (data) {
            this.openModalSettings = false
          }
        })
      }
    },
    changeShowPrice() {
      axios.post('/companies/change-show-price', this.firm).then(({data}) => {
        if(data) {
          // this.openModalSettings = false
        }
      })
    },
    storeFirmSettings() {
      axios.post('/companies/store-settings', this.firm).then(({data}) => {

        if(this.user.type === 'admin') {
          axios.post(`companies/${this.selectedCompany.id}/questionnaire`, {
            'questionnaires': this.questionares
          }).finally(() => this.questionares = [])
        }

        if(data) {
          this.openModalSettings = false
        }
      })
    },
    closeModalSettings() {
      this.openModalSettings = false
    },
    openSettings(item) {
      this.selectedCompany = item
      if(this.user.type === 'admin') {
        this.loadQuestionnaire()
      }

      this.openModalSettings = true
      if (item.settings == null) {
        item.settings = {
          shift_one: {
            delivery_from_hour: '',
            delivery_from_min: '',
            delivery_to_hour: '',
            delivery_to_min: '',
          },
          shift_two: {
            delivery_from_hour: '',
            delivery_from_min: '',
            delivery_to_hour: '',
            delivery_to_min: '',
          },
          shift_three: {
            delivery_from_hour: '',
            delivery_from_min: '',
            delivery_to_hour: '',
            delivery_to_min: '',
          },
          days: {
            monday: {
              time: {
                time_hour: '',
                time_minut: '',
              },
              day_before_limit: '',
              day_after_limit: '',
              // change_week: ''
            },
            tuesday: {
              time: {
                time_hour: '',
                time_minut: '',
              },
              day_before_limit: '',
              day_after_limit: '',
              // change_week: ''
            },
            wednesday: {
              time: {
                time_hour: '',
                time_minut: '',
              },
              day_before_limit: '',
              day_after_limit: '',
              // change_week: ''
            },
            thursday: {
              time: {
                time_hour: '',
                time_minut: '',
              },
              day_before_limit: '',
              day_after_limit: '',
              // change_week: ''
            },
            friday: {
              time: {
                time_hour: '',
                time_minut: '',
              },
              day_before_limit: '',
              day_after_limit: '',
              // change_week: ''
            },
            saturday: {
              time: {
                time_hour: '',
                time_minut: '',
              },
              day_before_limit: '',
              day_after_limit: '',
              // change_week: ''
            },
            sunday: {
              time: {
                time_hour: '',
                time_minut: '',
              },
              day_before_limit: '',
              day_after_limit: '',
              // change_week: ''
            },
            day: {
              monday: '',
              tuesday: '',
              wednesday: '',
              thursday: '',
              friday: '',
              saturday: '',
              sunday: '',
            }
          },
          order_from_one_shift_to_another_can: true,
          global_restaurant_time_settings: false,
          current_shift_meal: false,
          confirm_delivery: false,
          order_from_one_shift_to_another: true,
          can_see_report: false,
          all_cooperation: false
        }
      }
      if (typeof item.settings != 'string' && ! item.settings.hasOwnProperty('shift_one')) {
        item.settings = {
          shift_one: {
            delivery_from_hour: '',
            delivery_from_min: '',
            delivery_to_hour: '',
            delivery_to_min: '',
          },
          shift_two: {
            delivery_from_hour: '',
            delivery_from_min: '',
            delivery_to_hour: '',
            delivery_to_min: '',
          },
          shift_three: {
            delivery_from_hour: '',
            delivery_from_min: '',
            delivery_to_hour: '',
            delivery_to_min: '',
          },
          days: {
            monday: {
              time: {
                time_hour: '',
                time_minut: '',
              },
              day_before_limit: '',
              day_after_limit: '',
              // change_week: ''
            },
            tuesday: {
              time: {
                time_hour: '',
                time_minut: '',
              },
              day_before_limit: '',
              day_after_limit: '',
              // change_week: ''
            },
            wednesday: {
              time: {
                time_hour: '',
                time_minut: '',
              },
              day_before_limit: '',
              day_after_limit: '',
              // change_week: ''
            },
            thursday: {
              time: {
                time_hour: '',
                time_minut: '',
              },
              day_before_limit: '',
              day_after_limit: '',
              // change_week: ''
            },
            friday: {
              time: {
                time_hour: '',
                time_minut: '',
              },
              day_before_limit: '',
              day_after_limit: '',
              // change_week: ''
            },
            saturday: {
              time: {
                time_hour: '',
                time_minut: '',
              },
              day_before_limit: '',
              day_after_limit: '',
              // change_week: ''
            },
            sunday: {
              time: {
                time_hour: '',
                time_minut: '',
              },
              day_before_limit: '',
              day_after_limit: '',
              // change_week: ''
            },
            day: {
              monday: '',
              tuesday: '',
              wednesday: '',
              thursday: '',
              friday: '',
              saturday: '',
              sunday: '',
            }
          },
          order_from_one_shift_to_another_can: true,
          global_restaurant_time_settings: false,
          current_shift_meal: false,
          confirm_delivery: false,
          order_from_one_shift_to_another: true,
          can_see_report: false,
          all_cooperation: false
        }
      }

      if(typeof item.settings == 'string') {
        if(!JSON.parse(item.settings).order_from_one_shift_to_another) {
          JSON.parse(item.settings)['order_from_one_shift_to_another'] = true
        }
        item.settings = JSON.parse(item.settings)
      }
      this.firm = item
      if(! item.settings.hasOwnProperty('global_restaurant_time_settings')) {
        item.settings.global_restaurant_time_settings = false
      }
      if(! item.settings.hasOwnProperty('days')) {
        item.settings.days = {
          monday: {
            time: {
              time_hour: '',
              time_minut: '',
            },
            day_before_limit: '',
            day_after_limit: '',
            // change_week: ''
          },
          tuesday: {
            time: {
              time_hour: '',
              time_minut: '',
            },
            day_before_limit: '',
            day_after_limit: '',
            // change_week: ''
          },
          wednesday: {
            time: {
              time_hour: '',
              time_minut: '',
            },
            day_before_limit: '',
            day_after_limit: '',
            // change_week: ''
          },
          thursday: {
            time: {
              time_hour: '',
              time_minut: '',
            },
            day_before_limit: '',
            day_after_limit: '',
            // change_week: ''
          },
          friday: {
            time: {
              time_hour: '',
              time_minut: '',
            },
            day_before_limit: '',
            day_after_limit: '',
            // change_week: ''
          },
          saturday: {
            time: {
              time_hour: '',
              time_minut: '',
            },
            day_before_limit: '',
            day_after_limit: '',
            // change_week: ''
          },
          sunday: {
            time: {
              time_hour: '',
              time_minut: '',
            },
            day_before_limit: '',
            day_after_limit: '',
            // change_week: ''
          },
          day: {
            monday: '',
            tuesday: '',
            wednesday: '',
            thursday: '',
            friday: '',
            saturday: '',
            sunday: '',
          }
        }
      }
      this.$forceUpdate()
    },
    editNewCredit() {
      this.canSeeStoredCredit = true
      this.canSeeInputFields = false
      this.credit = ''
      this.modalCreditItems.naziv = ''
      this.modalCreditItems.rabat = ''
      this.modalCreditItems.percent = ''
      this.modalCreditItems.date_from = ''
      this.modalCreditItems.date_to = ''
      this.modalCreditItems.active = ''
      this.modalCreditItems.date = ''
    },
    addNewCredit() {
      this.canSeeStoredCredit = false
      this.canSeeInputFields = true
      this.modalCreditItems.naziv = ''
      this.modalCreditItems.rabat = ''
      this.modalCreditItems.percent = ''
      this.modalCreditItems.date_from = ''
      this.modalCreditItems.date_to = ''
      this.modalCreditItems.active = ''
      this.modalCreditItems.date = ''
    },
    creditIsActive(credits) {
      axios.post('/companies/credit-is-active', credits).then(({data}) => {
        this.storedCredits.filter(item => {
          if (item.id == data.id) {
            item = data
          }
        })
      })
    },
    selectCredit() {
      this.storedCredits.filter(item => {
        if (item.id == this.credit) {
          this.modalCreditItems = item
          this.modalCreditItems['naziv'] = item.name
          this.modalCreditItems['id'] = item.id
        }
      })
      this.canSeeInputFields = true
    },
    storeCredit() {
      axios.post('/companies/save-credit', this.modalCreditItems).then(({data}) => {
        if(data) {
          this.openModalAddCredit = false
          this.modalCreditItems.naziv = ''
          this.modalCreditItems.rabat = ''
          this.modalCreditItems.percent = ''
          this.modalCreditItems.date_from = ''
          this.modalCreditItems.date_to = ''
          this.modalCreditItems.active = ''
          this.modalCreditItems.date = ''
          this.credit = ''

          // this.getCredit()
        }
      })
    },
    getCredit(id) {
      axios.post('/companies/get-credit', {'company_id': id}).then(({data}) => {
        this.storedCredits = data
      })
    },
    openCredit(item) {
      this.getCredit(item.id)
      this.openModalAddCredit = true
      this.canSeeStoredCredit = true
      this.modalCreditItems = item
    },
    setCredit(item, index) {
      axios.put('/companies/' + item.id + '/is-credit', item).then(({data}) => {
        console.log(index)
      })
    },
    initialize() {
      axios.get("/companies").then((res) => {
        this.desserts = res.data;
      });
      axios.get("menus/menu-types").then((res) => {
        this.meniType = res.data;
        this.meniTypeOrg = res.data;
      });
    },
    editItem(item) {
      if (item.parent_id) {
        this.editedIndex = this.desserts.findIndex(
          (i) => i.id === item.parent_id
        );
        this.editedParentIndex = this.desserts[
          this.editedIndex
        ].children.findIndex((i) => (i.id = item.id));
      } else {
        this.editedIndex = this.desserts.indexOf(item);
      }
      this.editedItem = Object.assign({}, item);
      this.dialog = true;
    },
    deleteItem(item) {
      if (item.parent_id) {
        this.editedIndex = this.desserts.findIndex(
          (i) => i.id === item.parent_id
        );
        this.editedParentIndex = this.desserts[
          this.editedIndex
        ].children.findIndex((i) => (i.id = item.id));
      } else {
        this.editedIndex = this.desserts.indexOf(item);
      }
      this.editedItem = Object.assign({}, item);
      this.dialogDelete = true;
    },
    deleteItemConfirm() {
      axios.delete("/companies/" + this.editedItem.id).then((res) => {
        if (this.editedParentIndex > -1) {
          this.desserts[this.editedIndex].children.splice(
            this.editedParentIndex,
            1
          );
        } else {
          this.desserts.splice(this.editedIndex, 1);
        }
        this.closeDelete();
      });
    },
    close() {
      this.dialog = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
        this.editedParentIndex = -1;
      });
    },
    closeDelete() {
      this.dialogDelete = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
        this.editedParentIndex = -1;
      });
    },
    save() {
      this.editedItem.parent_id = this.editedItem.parent
        ? this.editedItem.parent.id
        : 0;
      if (this.editedIndex > -1 || this.editedParentIndex > -1) {
        let _editedIndex = this.editedIndex;
        let _editedParentIndex = this.editedParentIndex;
        let _editedItem = this.editedItem;

        axios
          .put("/companies/" + this.editedItem.id, this.editedItem)
          .then((res) => {
            if (_editedParentIndex > -1) {
              Object.assign(
                this.desserts[_editedIndex].children[_editedParentIndex],
                _editedItem
              );
            } else {
              Object.assign(this.desserts[_editedIndex], _editedItem);
            }
          });
      } else {
        let _editedItem = this.editedItem;
        axios.post("/companies", this.editedItem).then((res) => {
          _editedItem.id = res.data.id;
          if (_editedItem.parent_id) {
            let i = this.desserts.findIndex(
              (i) => i.id === _editedItem.parent_id
            );
            this.desserts[i].children.push(_editedItem);
          } else {
            this.desserts.push(_editedItem);
          }
        });
      }
      this.$forceUpdate()
      this.close();
    },
    print(id, type = false) {
      const prtHtml = document.getElementById(id).innerHTML;
      let stylesHtml = "";
      for (const node of [
        ...document.querySelectorAll('link[rel="stylesheet"], style'),
      ]) {
        stylesHtml += node.outerHTML;
      }
      // Open the print window
      const WinPrint = window.open(
        "",
        "",
        "left=0,top=0,width=800,height=900,toolbar=0,scrollbars=0,status=0"
      );

      WinPrint.document.write(`<!DOCTYPE html>
                                    <html>
                                      <head>
                                        ${stylesHtml}

                                      </head>
                                      <body>
                                        ${prtHtml}
                                      </body>
                                    </html>`);

      // WinPrint.document.close();
      // WinPrint.focus();
      // WinPrint.print();
      // WinPrint.close();
      WinPrint.document.close();
      WinPrint.focus();

      WinPrint.onload = function () {
        WinPrint.print();
        WinPrint.close();
      }
    },
    savePartners() {
        axios.post('/companies/'+ this.selectedCompany.id +'/add-restaurants', {
          restaurants: this.selectedRestaurants,
          times: this.times
        }).finally(() => {
          this.dialogPartner = false
        })
    },
    openPartner(item) {
      this.selectedCompany = item
      axios.get('/companies/'+ this.selectedCompany.id +'/show-restaurants').then(res => {
        this.selectedRestaurants = res.data
        this.dialogPartner = true
      })
    }
  }
};
</script>
<style>
@media (max-width: 599px) {
  header.dimensions1 {
    height: 120px !important;
  }
  header.dimensions1 > div {
    height: 100px !important;
  }
  .dim_margin {
    margin-top: -40px !important;
  }
}
.vreme .v-text-field__details {
    display: none;
}
.okolo {
  margin: 0 !important;
}
@media(max-width: 767px) {
  .okolo {
    display: block;
  }
  .v-card__text b {
    font-size: 16px;
  }
  #stampaj {
    display: none;
  }
}
.polje textarea {
    line-height: 0.9rem !important;
}
</style>
<style scoped>

</style>
