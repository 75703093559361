<template>
<div>
  <v-container style="max-width: 1170px;">
    <div class="text-center">
      <div class="qr-title">
        <h2 class="mb-5 mt-4">{{translate( $store.state.user.name) }} {{ translate($store.state.user.lastname) }} / ID: {{ $store.state.user.id}}</h2>
        <h4>{{$t('use_qr_web_mobile_login')}}</h4>
      </div>
    </div>
  </v-container>
  <v-container>
    <v-row align="center" justify="center">
      <vue-qrcode :value="qrCode" :options="{ width: '350' }"></vue-qrcode>
    </v-row>
          <v-row align="center" justify="center">
        
          <v-card-text align="center" justify="center" >
              {{$t('app_developed_by')}}<a href="https://bitlab.rs/" target="_blank" style="text-decoration: none"><strong  >{{translate(' БитЛаб')}}</strong></a>
            </v-card-text>  
        
      </v-row>
  </v-container>
</div>
</template>

<script>
import conf from "../config";
import VueQrcode from "@chenfengyuan/vue-qrcode";
import axios from "../plugins/axios";
export default {
  components: {
    VueQrcode,
  },
  data: () => ({
    conf: conf,
    qrCode: null,
  }),
  created() {
    this.qrCode = this.getQR();
  },
  methods: {
    getQR() {
      axios.get('/user/'+this.$store.state.user.id+'/hash').then((res) => {
        this.qrCode=JSON.stringify({ id: this.$store.state.user.id, secure: res.data });
      });
    },
  },
};
</script>

<style scoped>
.qr-title {
  display: flex;
  flex-direction: column;
}

.qr-title h2, .qr-title h4 {
  margin: 0 !important;
}

.qr-title h2{
  margin-top: 30px !important;
}

.qr-title h4 {
  font-weight: normal;
}
</style>