<template>
  <v-container>
      <v-card :key=index v-for="(restaurant, index) in restaurants"
      :to="{ name: 'restoran-dostava', params: { id: restaurant.id, name:restaurant.name} }"
      max-width="374" 
      class="mx-auto my-12">
        <v-img 
        height="250"
          :src="
            conf.apiUrlBase +
            '/gallery/' +
            restaurant.id +
            '/' +
            restaurant.settings.logo
          "
        ></v-img>
        <v-card-title>{{ restaurant.name }}</v-card-title>
        <v-card-subtitle>{{restaurant.address}}</v-card-subtitle>
        <v-card-text>

        </v-card-text>
      </v-card>
    
  </v-container>
</template>


<script>
import axios from "../plugins/axios";
import conf from "../config";
export default {
  data() {
    return {
    conf:conf,
      restaurants: null,
    };
  },
  created() {
    this.initialize();
  },
  methods: {
    initialize() {
      axios.get("/delivery").then((res) => {
        this.restaurants = res.data;
      });
    },
    
  },
};
</script>