var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticClass:"hero",style:(`background: url('https://app.restorani.bitlab.host/gallery/${_vm.restaurant.id}/medium/${_vm.restaurant.settings.header}'); background-size: cover; background-position: center;`)},[_c('img',{staticClass:"hero-logo",attrs:{"src":_vm.conf.apiUrlBase +
        '/gallery/' +
        _vm.restaurant.id +
        '/' +
        _vm.restaurant.settings.logo}})]),_c('v-container',{staticStyle:{"max-width":"1170px"}},[_c('v-row',[_c('h2',{staticClass:"mb-5 mt-4"},[_vm._v(_vm._s(_vm.translate( _vm.$store.state.user.name))+" "+_vm._s(_vm.translate(_vm.$store.state.user.lastname))+": "+_vm._s(_vm.translate("Историјат наруџби")))])]),_c('v-data-table',{attrs:{"dense":"","headers":_vm.headers,"items":_vm.meals},on:{"click:row":_vm.openDialog},scopedSlots:_vm._u([_vm._l((_vm.headers),function(header){return {key:`header.${header.value}`,fn:function({ header }){return [_vm._v(" "+_vm._s(_vm.translate(header.text))+" ")]}}}),{key:"item.meals",fn:function({ item }){return [_c('p',{staticClass:"naziv-obroka"},[_vm._v(_vm._s(_vm.translate(item.meals.name)))])]}},{key:"item.num",fn:function({ item,index }){return [_vm._v(" "+_vm._s(index+1)+" ")]}}],null,true)}),_c('v-dialog',{attrs:{"max-width":"60%","scrollable":""},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('v-card',[_c('v-card-text',[_c('v-card',{attrs:{"elevation":"0"}},[_c('v-card-title',[_c('v-spacer'),_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){_vm.dialog = false}}},[_c('v-icon',{attrs:{"x-large":""}},[_vm._v("mdi-close")])],1)],1),_c('v-card-text',{staticClass:"mb-4"},[_c('v-row',[_c('v-col',{attrs:{"cols":"12","sm":"6"}},[_c('v-img',{attrs:{"src":_vm.selectedMeal.image
                        ? _vm.conf.apiUrlBase +
                          '/gallery/' +
                          _vm.restaurant +
                          '/medium/' +
                          _vm.selectedMeal.image.name
                        : 'https://cdn.vuetifyjs.com/images/cards/cooking.png'},scopedSlots:_vm._u([{key:"placeholder",fn:function(){return [_c('v-row',{staticClass:"fill-height ma-0",attrs:{"align":"center","justify":"center"}},[_c('v-progress-circular',{attrs:{"indeterminate":"","color":"grey lighten-5"}})],1)]},proxy:true}])})],1),_c('v-col',{staticClass:"ml-sm-n16 my-sm-2 mt-sm-16",attrs:{"cols":"12","sm":"6"}},[_c('v-row',{staticClass:"fill-height",attrs:{"align":"end"}},[_c('v-card',{staticClass:"pa-5",attrs:{"elevation":"0"}},[_c('v-card-title',[_c('h3',[_vm._v(" "+_vm._s(_vm.translate(_vm.selectedMeal.meals.name))+" ")])]),_c('v-card-text',{staticClass:"mb-4"},[_c('v-row',{attrs:{"no-gutters":""}},[_vm._l((_vm.selectedMeal.meals.foods),function(eat,i){return [_c('v-col',{staticClass:"font-weight-bold pt-2",attrs:{"cols":"12"}},[_c('v-row',{attrs:{"no-gutters":"","justify":"space-between"}},[_c('span',[_c('h3',[_vm._v(_vm._s(_vm.translate(eat.name)))])])])],1),_c('v-col',{attrs:{"cols":"12"}},[_vm._v(_vm._s(_vm.translate(eat.description)))])]})],2)],1)],1)],1)],1)],1)],1)],1)],1)],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }