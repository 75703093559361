<template>
<div style="width: 95%;margin: 15px auto">
  <v-toolbar flat>
    <v-toolbar-title>{{ $t("orders") }}</v-toolbar-title>
    <v-divider class="mx-4" inset vertical></v-divider>
    <span class="dani_porudzbe">
      <a style="margin-right: 3px" @click.prevent="selectDay('today', date[0], '')" :class="activeTab[0] ? 'activeTab' : ''" class="rest-days" href="">{{ $t('today')}}</a>
      <a style="margin-right: 3px" @click.prevent="selectDay('tomorrow', date[0], '')" :class="activeTab[1] ? 'activeTab' : ''" class="rest-days" href="">{{ $t('tomorrow')}}</a>
      <a @click.prevent="selectDay('rest_days', date[0], '')" :class="activeTab[2] ? 'activeTab' : ''" class="rest-days" href="">{{ $t('days_rest')}}</a>
    </span>
    <v-autocomplete style="margin-top: 12px;margin-right: 15px;margin-left: 15px"
      :items="[
          {id: 2, name: 'prepared_for_delivery1'},
          {id: 3, name: 'booked_by_delivery'},
          {id: 4, name: 'ready'},
          {id: 5, name: 'taken_by_delivery1'},
          {id: 6, name: 'delivered_11'},
          {id: 9, name: 'order_not_taken1'},
        ]"
      dense
      :label="$t('group_meal2')"
      return-object
      :item-text="getItemText"
      item-value="id"
      v-model="status_id"
      @input="selectDay('', date[0]), status_id"
    >
    </v-autocomplete>
    <div style="width: 25%;margin-left: auto">
      <v-text-field
        class="pr-4 pb-2"
        v-model="search"
        append-icon="mdi-magnify"
        :label="$t('search')"
        single-line
        hide-details
        dense
      ></v-text-field>
    </div>
    <v-btn :style="'border-radius: 8px;border: 1px solid ' + $store.state.restaurant.settings.topbar.background + ';background: none;color: ' + $store.state.restaurant.settings.topbar.background + ';box-shadow: none;text-transform: capitalize'"
          dark
          class="mb-2 mr-2"
          v-bind="attrs"
          v-on="on"
          small
        @click="print('print')" >
       <v-icon class="mr-2">mdi-printer</v-icon>
       <span id="stampaj">{{ $t("print") }}</span>
     </v-btn>

    <v-dialog v-model="dialog" max-width="700px" scrollable>
      <template v-slot:activator="{ on, attrs }">
        <v-btn :style="'border-radius: 8px;border: 1px solid ' + $store.state.restaurant.settings.topbar.background + ';background: none;color: ' + $store.state.restaurant.settings.topbar.background + ';box-shadow: none;text-transform: capitalize'"
          dark
          class="mb-2"
          v-bind="attrs"
          v-on="on"
          small
         v-if="! $store.state.user.type == 'dostava'"
        >
          <v-icon>mdi-plus</v-icon>
          {{$t("companies_delivery")}}
        </v-btn>

      </template>

      <v-card>
        <v-card-title class="primary" style="display: flex;justify-content: space-between">
          <span class="headline white--text">{{ formTitle }} | ID: {{ editedItem.id }}</span>
          <span @click.prevent="dialog = false" style="color: #fff;padding: 1px 12px;border-radius: 50px;border:1px solid;cursor: pointer">
            x
          </span>
        </v-card-title>

        <v-card-text>
          <v-container style="padding-bottom: 10px !important;">
            <v-row>
              <v-col cols="12">
                <v-text-field
                  v-model="editedItem.name"
                  :rules="[$t('mandatory_field')]"
                  :label="$t('*title')"
                ></v-text-field>
              </v-col>
              <v-col v-if="desserts.length" cols="12">
                <v-select
                  v-model="editedItem.parent"
                  :items="[{name:'',id:null}].concat(desserts.filter((item) => item.id))"
                  :label="$t('parent_company')"
                  item-text="name"
                  item-id="id"
                  return-object
                ></v-select>
              </v-col>
              <v-row class="px-3">
                <v-col cols="6" class="mb-0 pb-0">
                  <v-select
                      @change="getCity()"
                      v-model="editedItem.country"
                      :items="countries"
                      :label="$t('country')"
                      item-text="name"
                      item-id="id"
                      return-object
                  ></v-select>
                </v-col>
                <v-col cols="6" class="mb-0 pb-0">
                  <v-select
                      v-model="editedItem.city"
                      @change="getRestaurantsByCity()"
                      :items="cities ? cities : []"
                      :label="$t('city')"
                      item-text="name"
                      item-id="id"
                      return-object
                  ></v-select>
                </v-col>
              </v-row>
<!--              <v-col cols="12">-->
<!--                <v-text-field-->
<!--                  v-model="editedItem.city"-->
<!--                  :label="$t('city')"-->
<!--                ></v-text-field>-->
<!--              </v-col>-->
              <v-col cols="12">
                <v-text-field
                  v-model="editedItem.address"
                  :label="$t('address')"
                ></v-text-field>
              </v-col>
              <v-row class="px-3">
                <v-col cols="6" class="mb-0 pb-0">
                  <v-text-field
                      v-model="editedItem.latitude"
                      :label="$t('lati1')">
                  </v-text-field>
                </v-col>
                <v-col cols="6" class="mb-0 pb-0">
                  <v-text-field
                      v-model="editedItem.longitude"
                      :label="$t('long1')">
                  </v-text-field>
                </v-col>
              </v-row>
              <v-row class="px-3">
                <v-col cols="6">
                  <v-text-field
                    v-model="editedItem.phone"
                    :label="$t('phone')"
                  ></v-text-field>
                </v-col>
                <v-col cols="6">
                  <v-text-field
                    v-model="editedItem.email"
                    :label="'* ' + $t('email')"
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row class="px-3">
                <v-col cols="6">
                  <v-text-field
                    v-model="editedItem.jib"
                    :label="$t('jib')"
                  ></v-text-field>
                </v-col>
                <v-col cols="6">
                  <v-text-field
                    v-model="editedItem.pib"
                    :label="$t('pib')"
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row class="px-3">
                <v-col cols="4">
                  <v-text-field
                      v-model="editedItem.owner"
                      :label="$t('owner')"
                  ></v-text-field>
                </v-col>
                <v-col cols="4">
                  <v-text-field
                      v-model="editedItem.contact_person"
                      :label="$t('contact_person')"
                  ></v-text-field>
                </v-col>
                <v-col cols="4">
                  <v-text-field
                      v-model="editedItem.contract"
                      :label="$t('contract')"
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-col cols="12">
                <v-textarea
                    class="polje"
                    v-model="editedItem.description"
                    :label="$t('note')"
                ></v-textarea>
              </v-col>
              <v-col cols="12" class="mb-4 pb-0">
                <h4 class="mb-6">{{ $t('pay_type') }}</h4>
                <div style="display: flex;justify-content: space-between">
                  <v-switch
                      v-model="editedItem.paying_type"
                      inset
                      :label="$t('by_month')"
                      :false-value="0"
                      :true-value="1"
                      @change="changeAmountMonth()"
                      dense
                  ></v-switch>
                  <v-switch
                      v-model="editedItem.paying_type"
                      inset
                      :label="$t('by_percent')"
                      @change="changeAmountPercent()"
                      :false-value="0"
                      :true-value="2"
                      dense
                  ></v-switch>
                  <v-switch
                      v-model="editedItem.paying_type"
                      inset
                      :label="$t('by_meal')"
                      @change="changeAmountMeal()"
                      :false-value="0"
                      :true-value="3"
                      dense
                  ></v-switch>
                </div>
                <span v-if="editedItem.paying_type">
                  <v-text-field type="number" style="width: 30%" v-model="editedItem.paying" :label="$t('amount') + ' ' + (editedItem.paying_type == 2 ? '%' : ($store.state.restaurant.settings.price ? $store.state.restaurant.settings.price : ''))"></v-text-field>
                </span>
              </v-col>
<!--              <v-col cols="12">-->
<!--                <a href="" @click.prevent="openModalSettings = true">-->
<!--                  <v-icon :title="$t('settings')" small class="mr-2"> mdi-cog-outline</v-icon>Ostala podesavanja-->
<!--                </a>-->
<!--              </v-col>-->

            </v-row>
          </v-container>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn  color="primary" text @click="close">
            {{ $t("cancel") }}
          </v-btn>
          <v-btn style="border-radius: 10px" color="primary" dark @click="save">
            {{ $t("save") }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="dialogDelete" max-width="500px">
      <v-card>
        <v-card-title class="headline">{{
          $t("delete_confirmation")
        }}</v-card-title>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="closeDelete"
            >{{ $t("cancel") }}</v-btn
          >
          <v-btn color="blue darken-1" text @click="deleteItemConfirm"
            >{{ $t("confirm") }}</v-btn
          >
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-toolbar>
  <v-data-table style="margin-top: 5px"
    :headers="headers"
    :items="desserts"
    :search="search"
    sort-by="calories"
    class="elevation-1"
    id="print"
    dense
    show-expand
  >
    <template v-for="header in headers" v-slot:[`header.${header.value}`]="{ header }"  >
      {{$t(header.text)}}
    </template>
    <template v-for="values in headers" v-slot:[`item.${values.value}`]="{ item }">
      {{translate(item[values.value])}}
    </template>
    <template v-slot:item.data-table-expand="{ item, isExpanded, expand }">
      <template v-if="item.children && item.children.length">
        <v-btn :style="'border-radius: 8px;border: 1px solid ' + $store.state.restaurant.settings.topbar.background + ';background: none;color: ' + $store.state.restaurant.settings.topbar.background + ';box-shadow: none;text-transform: capitalize'"
          dark
          small
          rounded
          @click="expand(true)"
          v-if="!isExpanded"
        >
        <v-icon>mdi-arrow-down</v-icon>
          {{ $t("branches") }}
        </v-btn>
        <v-btn color="primary" dark small rounded @click="expand(false)" v-else>
          <v-icon>mdi-arrow-up</v-icon>
          {{ $t("hide") }}
        </v-btn>
      </template>
    </template>
    
    <template v-slot:expanded-item="{ item, isExpanded, expand }">
      <template v-if="item.children">
        <td :colspan="headers.length" class="pa-0">
          <v-data-table
            dense
            :headers="headers"
            class="elevation-1 grey lighten-4"
            :items="item.children || []"
            :items-per-page="item.children.length"
            hide-default-footer
            hide-default-header
          >
            <v-spacer></v-spacer>
            <template v-slot:item.is_credit="{ item, index }">
              <div v-if="$store.state.user.type == 'restaurant' || $store.state.user.type == 'admin'" class="text-right">
                  <span class="d-flex">
                    <v-checkbox v-model="item.is_credit" @change="setCredit(item, index)"></v-checkbox>
                    <v-icon small @click="openCredit(item)" style="color: #1e7e34;margin-top: -14px">mdi-currency-eur</v-icon>
                  </span>
              </div>
            </template>
            <template v-slot:item.actions="{ item, index }">
              <v-btn
                small
                color="primary"
                text
                class="mx-2"
                :to="{ name: 'firma-id-korisnici', params: { id: item.id, name:item.name, parent_id:item.parent_id } }"
              >
                {{ $t("users") }} {{ item.users_count }}
              </v-btn>
              <v-icon small class="mr-2" @click="editItem(item)">
                mdi-pencil
              </v-icon>
              <v-icon :title="$t('settings')" small class="mr-2" @click="openSettings(item)"> mdi-cog-outline</v-icon>
              <v-icon small @click="deleteItem(item)"> mdi-delete </v-icon>
            </template>
          </v-data-table>
        </td>
      </template>
    </template>
    <template v-slot:item.is_credit="{ item, index }">
      <div v-if="$store.state.user.type == 'restaurant' || $store.state.user.type == 'admin'" class="text-right">
        <span class="d-flex">
          <v-checkbox v-model="item.is_credit" @change="setCredit(item, index)"></v-checkbox>
          <v-icon small @click="openCredit(item)" style="color: #1e7e34;margin-top: -14px">mdi-currency-eur</v-icon>
        </span>
      </div>
    </template>
    <template v-if="$store.state.user.type == 'dostava' || $store.state.user.type == 'admin_delivery'" v-slot:item.restaurant_name="{ item, index }">
      <v-icon @click.prevent="opentRestoranLocation(item)" class="mr-2">mdi mdi-map-marker</v-icon>
      <span :title="item.restaurant_phone">{{ item.restaurant_name }}</span>
    </template>
    <template v-if="$store.state.user.type == 'dostava' || $store.state.user.type == 'admin_delivery'" v-slot:item.status_order="{ item, index }">
      {{ statuses.find(id => id.id == item.status_order) ? $t(statuses.find(id => id.id == item.status_order).name) : '' }}
    </template>
    <template v-slot:item.actions="{ item }">
<!--      <v-btn-->
<!--        small-->
<!--        color="primary"-->
<!--        text-->
<!--        class="mx-2"-->
<!--        :to="{ name: 'firma-id-korisnici', params: { id: item.id,name:item.name, parent_id:item.parent_id, delivery: 'dostava' } }"-->
<!--        v-if="-->
<!--          (item.children && item.children.length == 0) ||-->
<!--          !item.hasOwnProperty('children')-->
<!--        "-->
<!--      >-->
<!--        {{ $t("users") }} {{ item.users_count }}-->
<!--      </v-btn>-->
<!--      <v-btn-->
<!--        small-->
<!--        color="primary"-->
<!--        text-->
<!--        class="mx-2"-->
<!--        :to="{ name: 'firma-id-korisnici', params: { id: item.id,name:item.name, delivery: 'dostava' } }"-->
<!--        v-else-->
<!--      >-->
<!--        {{ $t("users") }}-->
<!--        {{-->
<!--          (item.children || []).reduce((sum, item) => {-->
<!--            return item.users_count?sum + item.users_count:sum;-->
<!--          }, 0)-->
<!--        }}-->
<!--      </v-btn>-->
      <v-icon style="border: 1px solid; padding: 3px;border-radius: 5px" :title="$t('orders') + ' ' + item.order_id" small class="mr-2" @click="showOrders(item)"> mdi-silverware-fork-knife </v-icon>
      <span v-if="item.status_order == 5">
         <v-btn
             small
             color="primary"
             style="border: 1px solid;border-radius: 7px"
             text
             class="mx-2"
             @click.prevent="acceptOrder(item, 4)"
         >
          Zaduzio dostavljac
         </v-btn>
         <v-btn
             small
             color="primary"
             text
             class="mx-2"
             @click.prevent="cancelOrder(item)"
         >
           <span style="color: red;border: 1px solid red;padding: 1px 5px;border-radius: 7px">Otkazi</span>
         </v-btn>
      </span>
      <span v-if="item.status_order == 4">
         <v-btn
             small
             color="primary"
             style="border: 1px solid;border-radius: 7px"
             text
             class="mx-2"
             @click.prevent="orderDelivered(item)"
         >
          Isporuceno
         </v-btn>
         <v-btn
             small
             color="primary"
             style="border: 1px solid;border-radius: 7px"
             text
             class="mx-2"
             @click.prevent="cancelOrder(item)"
         >
           <span style="color: red;border: 1px solid red;padding: 1px 5px;border-radius: 7px">Otkazi</span>
         </v-btn>
      </span>
      <span v-if="item.status_order == 3">
         <v-btn
             small
             color="primary"
             style="border: 1px solid;border-radius: 7px"
             text
             class="mx-2"
             @click.prevent="orderDelivered(item)"
           >
          Isporuceno
         </v-btn>
         <v-btn
             small
             color="primary"
             text
             class="mx-2"
             @click.prevent="cancelOrder(item)"
         >
           <span style="color: red;border: 1px solid red;padding: 1px 5px;border-radius: 7px">Otkazi</span>
         </v-btn>
      </span>
      <v-btn v-if="item.status_order != 3 && item.status_order != 5 && item.status_order != 4 && item.status_order != 6"
          small
          style="border: 1px solid;border-radius: 7px"
          color="primary"
          text
          class="mx-2 "
          @click.prevent="acceptOrder(item, 3)"
      >
        Rezervisi
      </v-btn>
      <span v-if="item.status_order == 6">ISPORUCENO</span>
<!--      <v-icon :title="$t('settings')" small class="mr-2" @click="openSettings(item)"> mdi-cog-outline</v-icon>-->
<!--      <v-icon :title="$t('delete')" small @click="deleteItem(item)"> mdi-delete </v-icon>-->
    </template>
    <template v-slot:no-data>
      <v-btn :style="'border-radius: 8px;border: 1px solid ' + $store.state.restaurant.settings.topbar.background + ';background: none;color: ' + $store.state.restaurant.settings.topbar.background + ';box-shadow: none;text-transform: capitalize'" @click="initialize">
        {{ $t("reset") }}
      </v-btn>
    </template>
  </v-data-table>
  <v-dialog v-model="openModalAddCredit" max-width="700px" scrollable>
    <v-card>
      <v-card-title class="primary" style="width: 100%">
        <span style="font-size: 20px !important; justify-content: space-between;width: 100%" class="headline white--text text-center d-flex">
          {{ canSeeStoredCredit ? $t("credit") + ' ' + (modalCreditItems.company_id ? '| ID: ' +  modalCreditItems.id : '') : $t("add_credit") }}
          <span>
             <v-btn :style="canSeeStoredCredit ? 'background:#fff; color:#fb6d3b' : ''" style="border: 1px solid #fff;padding: 6px;border-radius: 5px" color="blue darken-1" text @click="editNewCredit()">
              <span :style="canSeeStoredCredit ? 'color:#fb6d3b' : 'color: #fff'" style="color: #fff;font-size: 11px;">{{ $t("edit_credit") }}</span>
            </v-btn>
            <v-btn :style="! canSeeStoredCredit ? 'background:#fff' : ''" class="ml-3" color="primary" style="color: #fff;border: 1px solid #fff;padding: 6px;border-radius: 5px" dark text @click="addNewCredit()">
              <span :style="! canSeeStoredCredit ? 'color:#fb6d3b' : 'color: #fff'" style="color: #fff;font-size: 11px;">{{ $t("add_credit1") }}</span>
            </v-btn>
          </span>
        </span>
        <v-spacer></v-spacer>
      </v-card-title>
      <v-card-text>
<!--        <p v-if="canSeeStoredCredit" style="font-size: 17px" class="mb-5 mt-3">{{ $t('credit') }}</p>-->
        <v-select class="mt-8"
          v-if="canSeeStoredCredit"
          :label="$t('credit')"
          v-model="credit"
          :items="storedCredits"
          hide-details
          outlined
          v-on:change="selectCredit"
          dense
          item-value="id"
          item-text="name"
        ></v-select>
        <div v-if="canSeeInputFields">
          <v-text-field class="mt-6"
              v-model="modalCreditItems.naziv"
              :label="$t('add_title')"
          ></v-text-field>
          <v-row>
            <v-col>
              <v-switch
                v-model="modalCreditItems.percent"
                inset
                :label="$t('percent')"
              ></v-switch>
            </v-col>
            <v-col>
              <v-text-field
                type="number"
                v-model="modalCreditItems.rabat"
                :label="$t('add_rabat')"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <v-menu
                  ref="menu"
                  v-model="menu"
                  :close-on-content-click="false"
                  :return-value.sync="modalCreditItems.date_from"
                  transition="scale-transition"
                  offset-y
                  max-width="290px"
                  min-width="290px"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                      v-model="modalCreditItems.date_from"
                      :label="$t('*inital_date')"
                      prepend-icon="mdi-calendar"
                      readonly
                      v-bind="attrs"
                      v-on="on"
                  ></v-text-field>
                </template>
                <v-date-picker
                    v-model="modalCreditItems.date_from"
                    no-title
                    scrollable
                >
                  <v-spacer></v-spacer>
                  <v-btn text color="primary" @click="menu = false">
                    {{ $t('cancel') }}
                  </v-btn>
                  <v-btn
                      text
                      color="primary"
                      @click="$refs.menu.save(modalCreditItems.date_from)">
                    OK
                  </v-btn>
                </v-date-picker>
              </v-menu>
            </v-col>
            <v-col>
              <v-menu
                  ref="menu1"
                  v-model="menu1"
                  :close-on-content-click="false"
                  :return-value.sync="modalCreditItems.date_to"
                  transition="scale-transition"
                  offset-y
                  max-width="290px"
                  min-width="290px"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                      v-model="modalCreditItems.date_to"
                      :label="$t('ending_date')"
                      prepend-icon="mdi-calendar"
                      readonly
                      v-bind="attrs"
                      v-on="on"
                  ></v-text-field>
                </template>
                <v-date-picker
                    v-model="modalCreditItems.date_to"
                    no-title
                    scrollable
                >
                  <v-spacer></v-spacer>
                  <v-btn text color="primary" @click="menu1 = false">
                    {{ $t('cancel') }}
                  </v-btn>
                  <v-btn
                      text
                      color="primary"
                      @click="$refs.menu1.save(modalCreditItems.date_to)">
                    OK
                  </v-btn>
                </v-date-picker>
              </v-menu>
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <v-switch
                  v-model="modalCreditItems.active"
                  inset
                  :label="$t('status')"
              ></v-switch>
            </v-col>
          </v-row>
        </div>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="blue darken-1" text @click="openModalAddCredit = false">
          <span style="color: #fb6d3b">{{ $t("cancel") }}</span>
        </v-btn>
        <v-btn color="primary" style="background: #fb6d3b;color: #fff" dark text @click="storeCredit()">
          <span style="color: #fff">{{ $t("save") }}</span>
        </v-btn>
        <v-spacer></v-spacer>
      </v-card-actions>
    </v-card>
  </v-dialog>
  <v-dialog v-model="openModalSettings" max-width="80%" scrollable>
    <v-card>
      <v-card-title class="primary" style="width: 100%;display: flex;justify-content: space-between">
        <span style="font-size: 20px !important; justify-content: space-between;width: 95%" class="headline white--text text-center d-flex">
          {{ $t('join_restoran')}} | {{ firm.name }} | {{ 'ID: ' + firm.id }}
        </span>
        <span @click.prevent="openModalSettings = false" style="color: #fff;padding: 1px 12px;border-radius: 50px;border:1px solid;cursor: pointer">
            x
          </span>
        <v-spacer></v-spacer>
      </v-card-title>
      <v-card-text>
        <v-row class="px-3 py-2">
          <v-col cols="4" class="mb-0 pb-0">
            <v-select
                @change="getCity()"
                v-model="editedItem.country"
                :items="countries"
                :label="'* ' + $t('country')"
                item-text="name"
                item-id="id"
                return-object
            ></v-select>
          </v-col>
          <v-col cols="4" class="mb-0 pb-0">
            <v-select
                v-model="editedItem.city"
                @change="getRestaurantsByCity()"
                :items="cities ? cities : []"
                :label="'* ' + $t('city')"
                item-text="name"
                item-id="id"
                return-object
            ></v-select>
          </v-col>
          <v-col cols="4" class="mb-0 pb-0">
            <v-text-field class="pt-4"
                          v-model="search_res"
                          append-icon="mdi-magnify"
                          :label="$t('search')"
                          single-line
                          hide-details
                          dense
            ></v-text-field>
          </v-col>
        </v-row>
      </v-card-text>
      <h3 class="px-8 mb-4 text-center" v-if="restornani">{{ $t('restaurants')}}</h3>
<!--      <div class="px-8" v-if="restornani">-->
<!--        <p v-for="restaurant in filteredItems" style="border-bottom: 1px solid #cfcfcf;">-->
<!--          <label :for="restaurant.id">-->
<!--            <input :id="restaurant.id" style="cursor: pointer" :value="restaurant.id" v-model="editedItem.restaurant_id" type="checkbox">-->
<!--            <span class="pl-3">{{ restaurant.name }}</span>-->
<!--          </label>-->
<!--        </p>-->
<!--      </div>-->
      <v-data-table
          dense
          :headers="headersRes"
          sort-by="calories"
          class="elevation-1"
          :items="restornani ? restornani : []"
      >
        <template v-for="header in headersRes" v-slot:[`header.${header.value}`]="{ header }">
          {{ $t(header.text) }}
        </template>
        <template v-slot:item.check="{ item }">
          <label :for="item.id">
            <input v-if="item.restoran_delivery_company.length && item.restoran_delivery_company.find(id => id.restaurant_id == item.id).restaurant_id" @change="selectRestoran(item)" :id="item.id" style="cursor: pointer" :value="item.id" v-model="item.restoran_delivery_company.find(id => id.restaurant_id == item.id).restaurant_id" type="checkbox">
            <input v-else @change="selectRestoran(item)" :id="item.id" style="cursor: pointer" :true-value="item.id" v-model="item.restaurant_id" type="checkbox">
          </label>
        </template>
        <template v-slot:item.active_restoran="{ item }">
          <label v-if="item.restoran_delivery_company.length && item.restoran_delivery_company.find(id => id.restaurant_id == item.id).restaurant_id" :for="item.id">
            <input :id="item.id" style="cursor: pointer" :value="item" v-model="item.restoran_delivery_company.find(id => id.restaurant_id == item.id).active_restoran" type="checkbox">
          </label>
          <label v-else :for="item.id">
            <input :id="item.id" style="cursor: pointer" :value="item" v-model="item.active_restoran" type="checkbox">
          </label>
        </template>
        <template v-slot:item.active_company="{ item }">
          <label v-if="item.restoran_delivery_company.length && item.restoran_delivery_company.find(id => id.restaurant_id == item.id).restaurant_id" :for="item.id">
            <input :id="item.id" style="cursor: pointer" :value="item.id" v-model="item.restoran_delivery_company.find(id => id.restaurant_id == item.id).active_company" type="checkbox">
          </label>
          <label v-else :for="item.id">
            <input :id="item.id" style="cursor: pointer" :value="item.id" v-model="item.active_company" type="checkbox">
          </label>
        </template>
        <template v-slot:item.paying_type="{ item }">
          <select v-if="item.restoran_delivery_company.length && item.restoran_delivery_company.find(id => id.restaurant_id == item.id).restaurant_id" style="padding: 6px 15px" v-model="item.restoran_delivery_company.find(id => id.restaurant_id == item.id).paying_type" id="">
            <option v-for="item in [
                {name: $t('choose'), val: 0},
                {name: $t('by_month'), val: 1},
                {name: $t('by_percent'), val: 2},
                {name: $t('by_meal'), val: 3},
              ]"
              :value="item.val">{{ item.name}}</option>
          </select>
          <select v-else style="padding: 6px 15px" v-model="item.paying_type" id="">
            <option v-for="item in [
                {name: $t('choose'), val: 0},
                {name: $t('by_month'), val: 1},
                {name: $t('by_percent'), val: 2},
                {name: $t('by_meal'), val: 3},
              ]"
              v-model="item.paying_type"
              :value="item.val">{{ item.name}}</option>
          </select>
        </template>
        <template v-slot:item.paying="{ item }">
          <v-text-field v-if="item.restoran_delivery_company.length && item.restoran_delivery_company.find(id => id.restaurant_id == item.id).restaurant_id"
            v-model="item.restoran_delivery_company.find(id => id.restaurant_id == item.id).paying"
            :label="$t('amount')"
            single-line
            hide-details
          ></v-text-field>
          <v-text-field v-else
            v-model="item.paying"
            :label="$t('amount')"
            single-line
            hide-details
          ></v-text-field>
        </template>
        <template v-slot:item.internal="{ item }">
          <label v-if="item.restoran_delivery_company.length && item.restoran_delivery_company.find(id => id.restaurant_id == item.id).restaurant_id" :for="item.id">
            <input :id="item.id" style="cursor: pointer" :value="item.id" v-model="item.restoran_delivery_company.find(id => id.restaurant_id == item.id ).internal" type="checkbox">
          </label>
          <label v-else :for="item.id">
            <input :id="item.id" style="cursor: pointer" :value="item.id" v-model="item.internal" type="checkbox">
          </label>
        </template>
      </v-data-table>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="blue darken-1" text @click="closeModalSettings()">
          <span style="color: #fb6d3b">{{ $t("cancel") }}</span>
        </v-btn>
        <v-btn color="primary" style="background: #fb6d3b;color: #fff" dark text @click="storeFirmSettings()">
          <span style="color: #fff">{{ $t("save") }}</span>
        </v-btn>
        <v-spacer></v-spacer>
      </v-card-actions>
    </v-card>
  </v-dialog>
  <v-dialog v-model="orders" max-width="50%" scrollable>
    <v-card>
      <v-card-title class="primary" style="width: 100%;display: flex;justify-content: space-between">
        <span style="font-size: 20px !important; justify-content: space-between;width: 95%" class="headline white--text text-center d-flex">
          ID: {{ single_order.id}}
        </span>
        <span @click.prevent="orders = false" style="color: #fff;padding: 1px 12px;border-radius: 50px;border:1px solid;cursor: pointer">
            x
          </span>
        <v-spacer></v-spacer>
      </v-card-title>
      <v-card-text class="py-4">
        <template v-for="order in single_order.order_guest_transactions">
          <div :style="order.meals || order.food ? 'border-bottom: 1px dashed #cfcfcf' : ''" style="width: 100%;" class="d-flex justify-content-center align-content-center w-100">
            <div :title="order.meals || order.food ? translate(order.meals ? order.meals.name : order.food.name) : ''" style="width: 55%;padding-bottom: 2px;font-size: 16px;color: #000000;font-weight: 500">
              {{ order.meals || order.food ? order.quantity + 'x ' : '' }}
              <span style="text-transform:uppercase;">{{ order.meals || order.food ? translate(order.meals ? order.meals.name : order.food.name) : '' }}</span>
            </div>
            <div style="width: 45%;text-align: right;font-size: 16px;padding-bottom: 2px">
              {{ order.meals || order.food ? order.meals ? order.meals.price * order.quantity : order.food.price * order.quantity : '' }}
            </div>
          </div>
          <div v-if="order.prilog.length" v-for="prilog in order.prilog">
            <div v-if="prilog.quantity > 0" style="color:#696969;width: 100%;text-transform:lowercase" class="d-flex justify-content-center align-content-center">
              <div style="width: 56%;font-size: 16px;padding-bottom: 2px;color: #818181">- {{ prilog.quantity }}x {{ translate(prilog.food.name) }}</div>
              <div style="width: 45%;text-align: right;font-size: 16px;padding-bottom: 2px">{{ prilog.quantity * prilog.price }}</div>
            </div>
          </div>
        </template>
        <div style="border-bottom: 1px solid #cfcfcf;"  class="d-flex justify-content-center align-content-center w-100">
          <div style="width: 50%;font-size: 16px;padding-bottom: 2px;font-weight: 500;">{{ $t('delivery')}}:</div>
          <div style="width: 50%;text-align: right;font-size: 16px;padding-bottom: 2px;font-weight: 500">
            + {{ $store.state.restaurant.settings.guest.restoran_delivery ? $store.state.restaurant.settings.guest.delivery_price : ''}}
          </div>
        </div>
        <div class="d-flex justify-content-center align-content-center">
          <div style="width: 55%;font-size: 12px;padding-bottom: 2px;font-weight: 700;">{{ $t('totalU')}} {{ $store.state.restaurant.settings && $store.state.restaurant.settings.price ? "(" + $store.state.restaurant.settings.price + ")" : '' }}:</div>
          <div style="width: 45%;text-align: right;font-size: 12px;padding-bottom: 2px;font-weight: 700">
            {{ priceMeals(single_order) }}
          </div>
        </div>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="blue darken-1" text @click="orders = false">
          <span style="color: #fb6d3b">{{ $t("cancel") }}</span>
        </v-btn>
<!--        <v-btn color="primary" style="background: #fb6d3b;color: #fff" dark text @click="storeFirmSettings()">-->
<!--          <span style="color: #fff">{{ $t("save") }}</span>-->
<!--        </v-btn>-->
        <v-spacer></v-spacer>
      </v-card-actions>
    </v-card>
  </v-dialog>
  <v-dialog v-model="restoran_location" max-width="50%" scrollable>
    <v-card>
      <v-card-title class="primary" style="width: 100%;display: flex;justify-content: space-between">
        <span style="font-size: 20px !important; justify-content: space-between;width: 95%" class="headline white--text text-center d-flex">
          {{ translate('Локација')}} {{ restoran_item.restaurant_name }}
        </span>
        <span @click.prevent="restoran_location = false" style="color: #fff;padding: 1px 12px;border-radius: 50px;border:1px solid;cursor: pointer">
            x
          </span>
        <v-spacer></v-spacer>
      </v-card-title>
      <v-card-text class="text-center py-6">
        <p>{{ translate('Адреса')}}: <span class="font-weight-bold ">{{ translate(restoran_item.restaurant_address) }}</span></p>
        <p>{{ translate('Град')}}: <span class="font-weight-bold">{{ translate(restoran_item.restaurant_city_name) }}</span></p>
        <p>{{ translate('Латитуда')}}: <span class="font-weight-bold">{{ translate(restoran_item.restaurant_latitude) }}</span></p>
        <p>{{ translate('Лонгитуда')}}: <span class="font-weight-bold">{{ translate(restoran_item.restaurant_longitude) }}</span></p>
        <p><a target="_blank" :href="'https://www.google.com/maps/search/?api=1&query= ' + restoran_item.restaurant_latitude + ' ,' + restoran_item.restaurant_longitude">Google maps</a></p>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
<!--        <v-btn color="blue darken-1" text @click="restoran_location = false">-->
<!--          <span style="color: #fb6d3b">{{ $t("cancel") }}</span>-->
<!--        </v-btn>-->
<!--        <v-btn color="primary" style="background: #fb6d3b;color: #fff" dark text @click="storeFirmSettings()">-->
<!--          <span style="color: #fff">{{ $t("save") }}</span>-->
<!--        </v-btn>-->
        <v-spacer></v-spacer>
      </v-card-actions>
    </v-card>
  </v-dialog>
</div>
</template>

<script>
import axios from "../plugins/axios";
import moment from 'moment'
import conf from "../config";
export default {
  data: () => ({
    conf: conf,
    dialog: false,
    firm: {
      settings: {
        confirm_delivery: false,
        order_from_one_shift_to_another: true,
        can_see_report: false
      }
    },
    cities: [],
    search: "",
    search_res: "",
    date: [new Date().toISOString().substr(0, 10)],
    on: undefined,
    attrs: undefined,
    credit: '',
    menu_type: "",
    status_id: 2,
    dialogDelete: false,
    restoran_location: false,
    restoran_item: false,
    openModalAddCredit: false,
    openModalSettings: false,
    openModalEditCredit: false,
    canSeeStoredCredit: true,
    canSeeInputFields: false,
    orders: false,
    menu: null,
    menu1: null,
    modalEditCreditItems: '',
    modalCreditItems: {
      naziv: '',
      rabat: '',
      percent: '',
      date_from: null,
      date_to: null,
      active: '',
      date: '',
    },
    statuses: [
      {id: 2, name: 'prepared_for_delivery1'},
      {id: 3, name: 'booked_by_delivery'},
      {id: 4, name: 'ready'},
      {id: 5, name: 'taken_by_delivery1'},
      {id: 6, name: 'delivered_11'},
      {id: 9, name: 'order_not_taken1'},
    ],
    storedCredits: [],
    headersRes: [
      {
        text: "status",
        align: "center",
        value: "check",
        //width: '25px'
      },
      {
        text: "id",
        align: "end",
        value: "id",
        //width: '25px'
      },
      {
        text: "name",
        align: "start",
        value: "name",
      },
      {
        text: "status_restoran",
        align: "start",
        value: "active_restoran",
        sortable: false,
      },
      {
        text: "status_company",
        align: "start",
        value: "active_company",
        sortable: false,
      },
      {
        text: "pay_type",
        align: "start",
        value: "paying_type",
        sortable: false,
      },
      {
        text: "amount",
        align: "start",
        value: "paying",
        sortable: false,
      },
      {
        text: "internal",
        align: "start",
        value: "internal",
        sortable: false,
      },
    ],
    headers: [
      // {
      //   text: "ID",
      //   align: "end",
      //   value: "order_id",
      //   //width: '25px'
      // },
      {
        text: "restaurant",
        align: "start",
        value: "restaurant_name",
      },
      // {
      //   text: "city",
      //   align: "start",
      //   value: "restaurant_city_name",
      //   sortable: false,
      // },
      // {
      //   text: "phone_no",
      //   align: "start",
      //   value: "restaurant_phone",
      //   sortable: false,
      // },
      {
        text: "date",
        align: "start",
        value: "delivery_date",
        sortable: true,
      },
      {
        text: "time",
        align: "start",
        value: "delivery_time",
        sortable: true,
      },
      {
        text: "delivery_address",
        align: "start",
        value: "delivery_address",
        sortable: false,
      },
      {
        text: "status",
        align: "start",
        value: "status_order",
        sortable: true,
      },
      {
        text: "actions",
        align: "center",
        value: "actions",
        sortable: false,
      },
      {
        text: "",
        value: "data-table-expand",
      },
    ],
    desserts: [],
    editedIndex: -1,
    editedParentIndex: -1,
    editedItem: {
      name: "",
      restaurant_id: [],
      active_restoran: [],
      active_company: [],
      paying_type: [],
      paying: [],
      internal: [],
      city: "",
      address: "",
      phone: "",
      email: "",
      lib: "",
      pib: "",
      description: "",
      state: 1,
      checkReport:0,
      shift_one: 1,
      shift_two: 2,
      shift_three: 3,
      contract: 1,
      lat: '',
      long: '',
      type:  'small'
    },
    defaultItem: {
      name: "",
      delivery: [],
      restaurant_id: [],
      active_restoran: [],
      active_company: [],
      paying_type: [],
      paying: [],
      internal: [],
      city: "",
      address: "",
      phone: "",
      email: "",
      lib: "",
      pib: "",
      description: "",
      state: 1,
      checkReport:0,
      shift_one: 1,
      shift_two: 2,
      shift_three: 3,
      contract: 1,
      lat: '',
      long: '',
      type:  'small'
    },
    user: '',
    delivery_from: [
      {text: '00', val: '00'},
      {text: '01', val: '01'},
      {text: '02', val: '02'},
      {text: '03', val: '03'},
      {text: '04', val: '04'},
      {text: '05', val: '05'},
      {text: '06', val: '06'},
      {text: '07', val: '07'},
      {text: '08', val: '08'},
      {text: '09', val: '09'},
      {text: '10', val: '10'},
      {text: '11', val: '11'},
      {text: '12', val: '12'},
      {text: '13', val: '13'},
      {text: '14', val: '14'},
      {text: '15', val: '15'},
      {text: '16', val: '16'},
      {text: '17', val: '17'},
      {text: '18', val: '18'},
      {text: '19', val: '19'},
      {text: '20', val: '20'},
      {text: '21', val: '21'},
      {text: '22', val: '22'},
      {text: '23', val: '23'},
      {text: '24', val: '24'},
    ],
    delivery_to: [
      {text: '00', val: '00'},
      {text: '15', val: '15'},
      {text: '30', val: '30'},
      {text: '45', val: '45'},
    ],
    countries: '',
    restornani: '',
    single_order: '',
    activeTab: [
      true,
      false,
      false,
    ],
    choosedDate: '',
    today: true,
    tommorow: false,
    rest_days: false,
  }),

  computed: {
    filteredItems() {
      let query = this.search_res.toLowerCase();
      if (query === '') {
        return this.restornani;
      } else {
        return this.restornani.filter(item => item.name.toLowerCase().includes(query));
      }
    },
    meniType() {
      return [
        {text:this.$t('small'),val:'small'},
        {text:this.$t('large'),val:'large'}
      ]
    },
    formTitle() {
      return this.editedIndex === -1 ? this.$t('new_company_delivery') : this.$t('change_companies_delivery');
    },
    getRole() {
      let usr = ''
      this.user.restaurants.filter(restoran => {
        restoran.users.filter(user => {
            if (user.id == this.user.id) {
              usr = user
            }
        })
      })
      return usr
    }
  },

  watch: {
    dialog(val) {
      val || this.close();
    },
    dialogDelete(val) {
      val || this.closeDelete();
    },
    "editedItem.contract"(newParam, oldParam) {
      if (newParam == 0) {
        this.menu_type.push({
          text:'за госта',val:'guest_meal'
        })
      }
      if (newParam == 1) {
        this.menu_type = [
          {text:this.$t('small'),val:'small'},
          {text:this.$t('large'),val:'large'}
        ]
      }
    }
  },

  created() {
    this.initialize();
    this.getCountry();
    this.user = this.$store.state.user;

    this.$root.$on('changeWebSite', (site) => {
      this.initialize();
    })
  },

  methods: {
    opentRestoranLocation(item) {
      this.restoran_location = true
      this.restoran_item = item
    },
    cancelOrder(item) {
      item['delivery_user'] = this.$store.state.user.id
      axios.post('/orders/cancel-delivery-user-status', item).then(({data}) => {
        this.desserts.filter(item => {
          if (item.order_id == data.id) {
            item.status_order = data.state
          }
        })
      })
    },
    orderDelivered(item) {
      item['delivery_user'] = this.$store.state.user.id
      axios.post('/orders/delivered-user-status', item).then(({data}) => {
        this.desserts.filter(item => {
          if (item.order_id == data.id) {
            item.status_order = data.state
          }
        })
      })
    },
    acceptOrder(item, status) {
      item['delivery_user'] = this.$store.state.user.id
      item['state'] = status
      console.log(status, item)
      axios.post('/orders/delivery-user-change-status', item).then(({data}) => {
        this.desserts.filter(item => {
          if (item.order_id == data.id) {
            item.status_order = data.state
          }
        })
      })
    },
    getItemText(item) {
      return this.$t(`${item.name}`);
    },
    selectDay(type, day) {
      if(type == 'today') {
        this.today = true
        this.tommorow = false
        this.rest_days = false
        this.activeTab[0] = true
        this.activeTab[1] = false
        this.activeTab[2] = false
        this.choosedDate = moment(day).format("YYYY-MM-DD")
        this.axiosNoContractNextDay(day, this.status_id.id)
      }
      if(type == 'tomorrow') {
        this.tommorow = true
        this.today = false
        this.rest_days = false
        this.activeTab[0] = false
        this.activeTab[1] = true
        this.activeTab[2] = false
        let nextDay = moment(day).add(1, 'days')
        this.choosedDate = moment(nextDay).format("YYYY-MM-DD")
        this.axiosNoContractNextDay(nextDay, this.status_id.id)
      }
      if(type == 'rest_days') {
        this.today = false
        this.tommorow = false
        this.rest_days = true
        this.activeTab[0] = false
        this.activeTab[1] = false
        this.activeTab[2] = true
        let restDay = moment(day).add(2, 'days')
        this.choosedDate = moment(restDay).format("YYYY-MM-DD")
        this.axiosNoContractNextDay(restDay, this.status_id.id)
      }
      if(type == '') {
        this.axiosNoContractNextDay(this.choosedDate, this.status_id.id)
      }
      this.$forceUpdate()
    },
    axiosNoContractNextDay(day, status) {
      this.desserts = []
      let statuses = ''
      let days = ''
      if (status) {
        statuses = status
      } else {
        statuses = 2
      }

      if (day == '') {
        days = moment().format('YYYY-MM-DD')
      } else {
        days = moment(day).format('YYYY-MM-DD')
      }
      axios.get('/orders/delivery-user-orders?user_id=' + this.$store.state.user.id + '&date=' + days + '&status=' + statuses).then(({data}) => {
        data.filter(item => {
        //   if (item.restaurant_id == this.$store.state.restaurant.id && this.status_id ? this.status_id.id : statuses == item.status_order) {
            this.desserts.push(item)
          // }
        })
        this.$forceUpdate()
      })
    },
    priceMeals(meal) {
      let total = 0;
      let totalP = 0;
      // meal.filter(or => {
      console.log(meal)
      if (meal && meal.order_guest_transactions) {
        meal.order_guest_transactions.filter(m => {
          if (m.prilog && m.prilog.length) {
            m.prilog.filter(pr => {
              totalP += (parseFloat(pr.price) * pr.quantity)
            })
          }
          if (m.meals && m.meals.price) {
            total += (parseFloat(m.meals.price) * (m.quantity ? m.quantity : 1))
          } else
          if (m.food && m.food.price) {
            total += (parseFloat(m.food.price) * (m.quantity ? m.quantity : 1))
          }
        })
      }

      // })
      // if(this.$store.state.restaurant.settings.guest.restoran_delivery) {
      //   return total + totalP + parseFloat(this.$store.state.restaurant.settings.guest.delivery_price)
      // }
      return total + totalP + parseFloat(this.$store.state.restaurant.settings.guest.restoran_delivery ?  this.$store.state.restaurant.settings.guest.delivery_price : 0)
    },
    selectRestoran(item) {
      console.log(item)
    },
    getRestaurantsByCity() {
      this.restornani = ''
      axios.post('/restaurants/get-restaurant-by-city', this.editedItem.city).then(({data}) => {
        this.restornani = data
      })
    },
    getCity() {
      this.cityes = []
      let cityes = []
      this.countries.filter(item => {
        item.city.filter(city => {
          this.editedItem.country.city.filter(c => {
            if (city.id == c.id) {
              cityes.push(city)
            }
          })
        })
      })
      this.cities = cityes
    },
    getCountry() {
      axios.get('/restaurants/countries-all').then(({data}) => {
        this.countries = data
      })
    },
    changeAmountMonth() {
      if (this.editedItem.by_month == 1) {
        this.editedItem.by_month = 1
      } else {
        this.editedItem.by_month = 0
      }
      this.editedItem.by_percent = 0
      this.editedItem.by_meal = 0
      this.editedItem.by_percent_input = ''
      this.editedItem.by_meal_input = ''
      this.$forceUpdate()
    },
    changeAmountPercent() {
      this.editedItem.by_meal = 0
      this.editedItem.by_month = 0
      if (this.editedItem.by_percent == 2) {
        this.editedItem.by_percent = 2
      } else {
        this.editedItem.by_percent = 0
      }
      this.editedItem.by_month_input = ''
      this.editedItem.by_meal_input = ''
      this.$forceUpdate()
    },
    changeAmountMeal() {
      if (this.editedItem.by_meal == 3) {
        this.editedItem.by_meal = 3
      } else {
        this.editedItem.by_meal = 0
      }
      this.editedItem.by_month = 0
      this.editedItem.by_percent = 0
      this.editedItem.by_month_input = ''
      this.editedItem.by_percent_input = ''
      this.$forceUpdate()
    },
    storeFirmSettings() {
      let data = {}
      data['restorani'] = this.restornani
      data['delivery_company'] = this.firm
      data['restoran_id'] = this.$store.state.restaurant.id
      axios.post('/companies/companies-delivery-restaurant', data).then(({data}) => {
        console.log(data)
      })
    },
    closeModalSettings() {
      this.openModalSettings = false
    },
    openSettings(item) {
      this.openModalSettings = true
      if (item.settings == null) {
        item.settings = {
          shift_one: {
            delivery_from: '',
            delivery_to: '',
            delivery_from1: '',
            delivery_to1: '',
          },
          shift_two: {
            delivery_from: '',
            delivery_to: '',
            delivery_from1: '',
            delivery_to1: '',
          },
          shift_three: {
            delivery_from: '',
            delivery_to: '',
            delivery_from1: '',
            delivery_to1: '',
          },
          confirm_delivery: false,
          order_from_one_shift_to_another: true,
          can_see_report: false
        }
      } else {
        if(typeof item.settings == 'string') {
          if(!JSON.parse(item.settings).order_from_one_shift_to_another) {
            JSON.parse(item.settings)['order_from_one_shift_to_another'] = true
          }
          item.settings = JSON.parse(item.settings)
        }
      }
      this.firm = item
      this.$forceUpdate()
    },
    editNewCredit() {
      this.canSeeStoredCredit = true
      this.canSeeInputFields = false
      this.credit = ''
      this.modalCreditItems.naziv = ''
      this.modalCreditItems.rabat = ''
      this.modalCreditItems.percent = ''
      this.modalCreditItems.date_from = ''
      this.modalCreditItems.date_to = ''
      this.modalCreditItems.active = ''
      this.modalCreditItems.date = ''
    },
    addNewCredit() {
      this.canSeeStoredCredit = false
      this.canSeeInputFields = true
      this.modalCreditItems.naziv = ''
      this.modalCreditItems.rabat = ''
      this.modalCreditItems.percent = ''
      this.modalCreditItems.date_from = ''
      this.modalCreditItems.date_to = ''
      this.modalCreditItems.active = ''
      this.modalCreditItems.date = ''
    },
    creditIsActive(credits) {
      axios.post('/companies/credit-is-active', credits).then(({data}) => {
        this.storedCredits.filter(item => {
          if (item.id == data.id) {
            item = data
          }
        })
      })
    },
    selectCredit() {
      this.storedCredits.filter(item => {
        if (item.id == this.credit) {
          this.modalCreditItems = item
          this.modalCreditItems['naziv'] = item.name
          this.modalCreditItems['id'] = item.id
        }
      })
      this.canSeeInputFields = true
    },
    storeCredit() {
      axios.post('/companies/save-credit', this.modalCreditItems).then(({data}) => {
        if(data) {
          this.openModalAddCredit = false
          this.modalCreditItems.naziv = ''
          this.modalCreditItems.rabat = ''
          this.modalCreditItems.percent = ''
          this.modalCreditItems.date_from = ''
          this.modalCreditItems.date_to = ''
          this.modalCreditItems.active = ''
          this.modalCreditItems.date = ''
          this.credit = ''

          // this.getCredit()
        }
      })
    },
    getCredit(id) {
      axios.post('/companies/get-credit', {'company_id': id}).then(({data}) => {
        this.storedCredits = data
      })
    },
    openCredit(item) {
      this.getCredit(item.id)
      this.openModalAddCredit = true
      this.canSeeStoredCredit = true
      this.modalCreditItems = item
    },
    setCredit(item, index) {
      axios.put('/companies/' + item.id + '/is-credit', item).then(({data}) => {
        console.log(index)
      })
    },
    initialize() {
      axios.post("/orders/delivery-order", {'res_id': 91}).then(({data}) => {
        console.log(data)
      });
     let days = ''
      this.desserts = []
      let status = ''
      if (!this.status_id) {
        status = 2
      } else {
        status = this.status_id
      }
      if (this.choosedDate == '') {
        days = moment().format('YYYY-MM-DD')
      } else {
        days = moment(this.choosedDate).format('YYYY-MM-DD')
      }
      axios.get('/orders/delivery-user-orders?user_id=' + this.$store.state.user.id + '&date=' + days + '&status=' + status).then(({data}) => {
        data.filter(item => {
        //   if (item.restaurant_id == this.$store.state.restaurant.id) {
            this.desserts.push(item)
          // }
        })
       // this.desserts = data
      })
      axios.get('/orders/guest-orders/' + 158 + '/show').then(({data}) => {
        this.single_order = data
      })
    },

    editItem(item) {
      if (item.parent_id) {
        this.editedIndex = this.desserts.findIndex(
          (i) => i.id === item.parent_id
        );
        this.editedParentIndex = this.desserts[
          this.editedIndex
        ].children.findIndex((i) => (i.id = item.id));
      } else {
        this.editedIndex = this.desserts.indexOf(item);
      }

      this.editedItem = Object.assign({}, item);
      this.dialog = true;
    },

    showOrders(item) {
      axios.get('/orders/guest-orders/' + item.order_id + '/show').then(({data}) => {
        console.log(data)
        this.orders = true
        this.single_order = data
      })
    },

    deleteItem(item) {
      if (item.parent_id) {
        this.editedIndex = this.desserts.findIndex(
          (i) => i.id === item.parent_id
        );
        this.editedParentIndex = this.desserts[
          this.editedIndex
        ].children.findIndex((i) => (i.id = item.id));
      } else {
        this.editedIndex = this.desserts.indexOf(item);
      }
      this.editedItem = Object.assign({}, item);
      this.dialogDelete = true;
    },

    deleteItemConfirm() {
      axios.delete("/companies/" + this.editedItem.id).then((res) => {
        if (this.editedParentIndex > -1) {
          this.desserts[this.editedIndex].children.splice(
            this.editedParentIndex,
            1
          );
        } else {
          this.desserts.splice(this.editedIndex, 1);
        }
        this.closeDelete();
      });
    },

    close() {
      this.dialog = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
        this.editedParentIndex = -1;
      });
    },

    closeDelete() {
      this.dialogDelete = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
        this.editedParentIndex = -1;
      });
    },

    save() {
      this.editedItem.parent_id = this.editedItem.parent
        ? this.editedItem.parent.id
        : 0;
      if (this.editedIndex > -1 || this.editedParentIndex > -1) {
        let _editedIndex = this.editedIndex;
        let _editedParentIndex = this.editedParentIndex;
        let _editedItem = this.editedItem;

        axios
          .put("/companies/" + this.editedItem.id + '/companies-delivery', this.editedItem)
          .then((res) => {
            if (_editedParentIndex > -1) {
              Object.assign(
                this.desserts[_editedIndex].children[_editedParentIndex],
                _editedItem
              );
            } else {
              Object.assign(this.desserts[_editedIndex], _editedItem);
            }
          });
      } else {
        let _editedItem = this.editedItem;
        axios.post("/companies" + '/companies-delivery', this.editedItem).then((res) => {
          _editedItem.id = res.data.id;
          if (_editedItem.parent_id) {
            let i = this.desserts.findIndex(
              (i) => i.id === _editedItem.parent_id
            );
            this.desserts[i].children.push(_editedItem);
          } else {
            this.desserts.push(_editedItem);
          }
        });
      }
      this.close();
    },
    print(id, type = false) {
      const prtHtml = document.getElementById(id).innerHTML;
      let stylesHtml = "";
      for (const node of [
        ...document.querySelectorAll('link[rel="stylesheet"], style'),
      ]) {
        stylesHtml += node.outerHTML;
      }
      console.log(stylesHtml);
      // Open the print window
      const WinPrint = window.open(
        "",
        "",
        "left=0,top=0,width=800,height=900,toolbar=0,scrollbars=0,status=0"
      );

      WinPrint.document.write(`<!DOCTYPE html>
                                    <html>
                                      <head>
                                        ${stylesHtml}

                                      </head>
                                      <body>
                                        ${prtHtml}
                                      </body>
                                    </html>`);

      WinPrint.document.close();
      WinPrint.focus();
      WinPrint.print();
      WinPrint.close();

    },
  },
};
</script>
<style>
.dani_porudzbe {
  display: flex;justify-content: space-between;align-items: center;height: 100%
}
.vreme .v-text-field__details {
    display: none;
}
.okolo {
  margin: 0 !important;
}

@media(max-width: 767px) {
  .okolo {
    display: block;
  }
  .v-card__text b {
    font-size: 16px;
  }
  #stampaj {
    display: none;
  }
}
.polje textarea {
    line-height: 0.9rem !important;
}
@media(max-width: 450px) {
  .v-toolbar__title, .v-toolbar__content hr {
    display: none;
  }
}
</style>
<style scoped>

</style>