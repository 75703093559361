<template>
<div>
  <v-container style="max-width: 1170px;">
    <v-row>
      <h2 class="mb-5">{{translate( $store.state.user.name) }} {{ translate($store.state.user.lastname) }} / {{ $t("password_change") }}</h2>
    </v-row>
  </v-container>
  <v-container style="max-width: 500px;">

    <v-card-text>
      <v-form>
        <v-text-field
          v-model="newPass"
          :label="$t('enter_new_password')"
          name="newPassword"
          prepend-icon="mdi-lock"
          type="password"
        ></v-text-field>
        <v-text-field
          v-model="newPassCheck"
          :label="$t('confirm_new_pass')"
          name="newPasswordCheck"
          prepend-icon="mdi-lock"
          type="password"
        ></v-text-field>
        <v-alert dense outlined type="error" v-if="failedCheck">
          {{$t('entered_passwords_do_not_match')}}
        </v-alert>
        <v-text-field
          v-model="currPass"
          :label="$t('enter_your_password')"
          name="currPassword"
          prepend-icon="mdi-lock"
          type="password"
        ></v-text-field>

        <v-alert dense outlined type="error" v-if="failedPass">
          {{$t('incorrect_password')}}
        </v-alert>
      </v-form>
    </v-card-text>
    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn color="primary" @click="update">{{$t('confirm_pass_change')}}</v-btn>
      <v-spacer></v-spacer>
    </v-card-actions>
  </v-container>
  </div>
</template>
<script>
import axios from "../plugins/axios";
export default {
  data() {
    return {
      failedPass: false,
      failedCheck: false,
      newPass: null,
      newPassCheck: null,
      currPass: null,
    };
  },
  methods: {
    update() {
      this.failedCheck=false;
      this.failedPass=false;
      if (this.newPass != this.newPassCheck) {
        this.failedCheck = true;
      } else {
        axios
          .post("/user/change-password",{
            new_password: this.newPass,
            old_password: this.currPass,
            id:this.$store.state.user.id
          })
          .then((res) => {
            this.$router.push({name: 'profil'})
          })
          .catch(() => {
            this.failedPass=true;
          });
      }
    },
  },
};
</script>